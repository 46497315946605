:root {
  --shop-color-border: #dddddd;
  --shop-color-bgindex: #ffffff;
  --shop-color-bgmobile: #f6f6f6;
  --coupon-bkg: #fffcc3;
  --coupon-text: #eb0036;
}

.list-blogs-latest .item-article .post-content h3 a {
  white-space: initial;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.hidden {
  display: none;
}

/*===== body ===================*/
* {
  margin: 0;
  padding: 0;
}

img,
iframe {
  max-width: 100%;
}

table {
  width: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  font-size: 15px;
  color: #333333;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #096743;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #096743;
}

p {
  margin-bottom: 10px;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
}

a {
  color: #333333;
}

a:hover {
  color: #dd0c1b;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}

a,
button,
input {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
textarea {
  border: 1px solid #b5b5b5;
  padding: 8px 15px;
  font-size: 14px;
  color: #757575;
  border-radius: 0;
}

button,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
textarea:focus {
  border-color: #c1bcbc;
  outline: none;
}

fieldset legend {
  display: inline-block;
  color: inherit;
  background: none;
  vertical-align: top;
}

table {
  width: 100%;
  border-spacing: 0;
}

table tr td {
  border-top: 1px solid #dddddd;
}

table tr td,
table tr th {
  padding: 10px;
  text-align: left;
}

table tr td:first-child,
table tr th:first-child {
  padding-left: 0px;
}

table tr td:last-child,
table tr th:last-child {
  padding-right: 0px;
}

/* 1.5 - Lists */
ul,
ol {
  padding: 0;
  margin: 0 0 15px;
  list-style-type: none;
}

.typeList-style * {
  max-width: 100% !important;
}

.typeList-style ul {
  list-style: initial;
  padding-left: 20px;
}

.typeList-style ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.typeList-style ul ul {
  list-style-type: circle;
}

.typeList-style ul ul ul {
  list-style-type: square;
}

.typeList-style ul li,
.typeList-style ol li {
  margin: 5px 0;
}

/*  Anmaiton css3 */
@-webkit-keyframes sticky_header_animation {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes sticky_header_animation {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes slideshowFade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideshowFade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotateIconLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*  CSS  General */
.container {
  max-width: 1200px;
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1440px;
    padding: 0 30px;
  }
}

.owl-carousel .owl-item img,
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: initial !important;
}

img.owl-lazy:not([src]) {
  visibility: hidden;
}

.owl-carousel .owl-item img {
  width: auto !important;
  text-align: center;
  margin: auto;
  overflow: hidden;
}

.ratiobox {
  position: relative;
  display: block;
}

.ratiobox .ls-blur-up-is-loading,
.ratiobox .lazyload:not([src]) {
  visibility: hidden;
}

.ratiobox .ls-blur-up-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  font-family: "blur-up: auto", "object-fit: contain";
  -o-object-fit: contain;
  object-fit: contain;
  filter: blur(8px);
  opacity: 1;
  transition: opacity 500ms, filter 700ms;
}

.ratiobox .ls-blur-up-img.ls-inview.ls-original-loaded {
  opacity: 0;
  filter: blur(5px);
}

/* END ++++ lazyload */
.view-more-text {
  color: #096743;
  text-decoration: underline;
}

.flex-center {
  align-items: center;
  display: flex;
  display: -o-flex;
  display: -moz-flex;
  display: -webkit-flex;
}

.section-head {
  margin: 0 0 40px 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-head {
    margin: 0 0 20px 0;
  }
}
.section-head h2 {
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 10px 0;
}
.section-head h2.color-white {
  color: white;
}
.section-head h3 {
  font-size: 16px;
  font-weight: 500;
  color: #096743;
}

body.block-scroll {
  overflow: hidden;
}

/* @media (max-width: 991.9px) {
  body.scroll-body:not(.scroll-body-up)
    .section-index-listmenu
    .listmenu-content
    .orders-cate {
    
    top: 0;
  }
}

@media (max-width: 991.9px) {
  body.scroll-body.scroll-body-up
    .section-index-listmenu
    .listmenu-content
    .orders-cate {
    top: var(--header-height);
    border-top: none;
  }
} */

@media (max-width: 991.9px) {
  .listmenu-site-nav {
    position: sticky;
    top: 0;
    z-index: 10; /* Adjust the z-index if needed */
  }
}


.heading-page h1 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .heading-page:after {
    content: "";
    background: #252a2b;
    display: block;
    width: 60px;
    height: 4px;
    margin: 25px auto 0;
  }
  .heading-page h1 {
    font-size: 30px;
    margin: 0px 0;
  }
}

.btn {
  background: #096743;
  color: white;
  border-radius: 30px;
  padding: 11px 50px;
  font-size: 14px;
  min-width: 170px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.btn:hover {
  color: white;
  outline: 0;
  box-shadow: none;
  background: #dd0c1b;
}
.btn:focus,
.btn:active {
  color: white;
  outline: 0;
  box-shadow: none;
  background: #dd0c1b;
}

/* Breadcrums */
.breadcrumb-theme {
  position: relative;
  margin-top: -1px;
  margin-bottom: 40px;
  padding: 0 0px;
  font-size: 13px;
  background: #f5f5f5;
}
.breadcrumb-theme .breadcrumb-list .breadcrumb {
  padding: 12px 0;
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  list-style: none;
}
.breadcrumb-theme .breadcrumb-list .breadcrumb > li {
  display: inline-block;
}
.breadcrumb-theme .breadcrumb-list .breadcrumb > li a {
  color: #333333;
  opacity: 0.8;
}
.breadcrumb-theme .breadcrumb-list .breadcrumb > li strong {
  font-weight: 500;
  opacity: 1;
}
.breadcrumb-theme .breadcrumb-list .breadcrumb > li + li:before {
  padding: 0 0px;
  color: #ccc;
  content: "/\00a0";
}

.pagination-theme {
  margin: 20px 0 40px 0;
}
.pagination-theme .page-node,
.pagination-theme .next,
.pagination-theme .prev {
  display: inline-block;
  margin: 30px 8px;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.4;
}
.pagination-theme .next {
  margin-left: 30px;
}
.pagination-theme .prev {
  margin-right: 30px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.pagination-theme .page-node.current,
.pagination-theme .page-node:hover,
.pagination-theme .next:hover,
.pagination-theme .prev:hover {
  opacity: 1;
}

/* Header */
.sitenav-search {
  margin-bottom: 20px;
}
.sitenav-search form.searchform {
  position: relative;
  padding: 0;
}
.sitenav-search form.searchform input.input-search {
  height: 44px;
  line-height: 28px;
  padding: 8px 50px 8px 20px;
  width: 100%;
  background: #f6f6f6;
  border: 1px solid var(--shop-color-border);
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.sitenav-search form.searchform .btn-search {
  width: 50px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  padding: 0;
  top: 0px;
  right: 0px;
  background: transparent;
  transition: opacity 150ms linear;
}
.sitenav-search form.searchform .btn-search svg {
  width: 18px;
  height: 44px;
  opacity: 0.4;
}
.sitenav-search form.searchform input.input-search:focus {
  background: #f2f5fa;
  outline: none;
  border-color: #096743;
}
.sitenav-search form.searchform.expanded .btn-search svg {
  opacity: 1;
}
.sitenav-search .smart-search-wrapper .dataEmpty {
  padding-bottom: 0px;
}

.sitenav-cart .cart-view-scroll .mini-cart__item {
  position: relative;
  border-bottom: 1px dotted var(--shop-color-border);
}
.sitenav-cart .cart-view-scroll .mini-cart__item:last-child {
  border-bottom: 0;
}
.sitenav-cart .cart-view-scroll .mini-cart__item td {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
}
.sitenav-cart .cart-view-scroll .mini-cart__item .mini-cart__left {
  width: 90px;
  padding-right: 15px;
  max-width: none;
  text-align: center;
}
.sitenav-cart .cart-view-scroll .mini-cart__item .mini-cart__left img {
  display: block;
  border: 1px solid #ededed;
}
.sitenav-cart .cart-view-scroll .mini-cart__item .mini-cart__right {
  padding-left: 5px;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__title
  .mnc-title {
  display: block;
  font-size: 12px;
  font-weight: 600;
  padding-right: 28px;
  text-transform: uppercase;
  color: #333333;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__title
  .mnc-variant {
  display: block;
  font-size: 13px;
  margin: 0px 0 7px;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__quantity
  .mnc-value {
  float: left;
  width: auto;
  background: #f7f7f7;
  text-align: center;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1;
  margin-right: 12px;
  color: #252a2b;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__price
  .mnc-price {
  display: block;
  text-align: center;
  float: left;
  line-height: 26px;
  font-weight: 500;
  opacity: 0.7;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__remove {
  position: absolute;
  right: 0px;
  top: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__remove
  a {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: block;
  color: #333333;
}
.sitenav-cart
  .cart-view-scroll
  .mini-cart__item
  .mini-cart__right
  .mini-cart__remove
  a
  svg {
  width: 12px;
}

.sitenav-cart .cart-view-scroll .mini-cart__empty td {
  padding-top: 0;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
}

.sitenav-cart .cart-view-scroll .mini-cart__empty .svgico-mini-cart {
  padding: 0px 0 5px;
}

.sitenav-cart .cart-view-scroll .mini-cart__empty .svgico-mini-cart svg {
  stroke: #096743;
  width: 50px;
  height: 50px;
  margin: auto;
}

.sitenav-cart .cart-view-line {
  clear: both;
  width: 100%;
  border-top: 1px solid var(--shop-color-border);
  margin: 0 0 5px;
}

.sitenav-cart .cart-view-total .mnc-total {
  font-weight: 500;
  color: #252a2b;
  padding: 10px 0;
}

.sitenav-cart .cart-view-total #total-view-cart {
  font-weight: 600;
  font-size: 16px;
  color: red;
}

.sitenav-cart .cart-view-total .mini-cart__button a.linktocheckout,
.sitenav-cart .cart-view-total .mini-cart__button a.linktocart {
  margin: 5px 0 10px;
  font-size: 12px;
  padding: 10px 10px;
  width: 100%;
}

.sitenav-cart .cart-view-total .mini-cart__button a.linktocart {
  color: #096743;
  background: #096743;
}
.sitenav-cart .cart-view-total .mini-cart__button a.linktocart:before {
  background-color: #ffffff;
}
.sitenav-cart .cart-view-total .mini-cart__button a.linktocart:hover,
.sitenav-cart .cart-view-total .mini-cart__button a.linktocart:focus {
  color: #ffffff;
  background: #096743;
}

.sitenav-cart .cart-view-total .mini-cart__button td:first-child {
  padding-right: 7px;
}

.sitenav-cart .cart-view-total .mini-cart__button td:last-child {
  padding-left: 7px;
}

@media (min-width: 992px) {
  .sitenav-cart .cart-view-scroll {
    overflow-y: scroll;
    max-height: 320px;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    margin-right: -20px;
    padding-right: 20px;
  }
  .sitenav-cart .cart-view-scroll::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
  .sitenav-cart .cart-view-scroll::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
  .sitenav-cart .cart-view-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #e1e3e4;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .sitenav-cart
    .cart-view
    .cart-view-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: #e1e3e4;
  }
}

.sitenav-account .site_account_panel_list {
  position: relative;
  height: 100%;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
.sitenav-account .site_account_panel_list .site_account_header {
  margin: 0 0 20px;
  padding-bottom: 8px;
  text-align: center;
  border-bottom: 1px solid var(--shop-color-border);
}
.sitenav-account
  .site_account_panel_list
  .site_account_header
  .site_account_title {
  font-size: 18px;
  color: #096743;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  margin: 0;
}
.sitenav-account
  .site_account_panel_list
  .site_account_header
  .site_account_title.size-small {
  font-size: 15px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_header
  .site_account_legend {
  margin: 5px 0 0;
  font-size: 14px;
}
.sitenav-account .site_account_panel_list .site_account_panel {
  width: 100%;
  visibility: hidden;
  transition: visibility 400ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1),
    visibility 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1),
    visibility 400ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel.site_account_default {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel.site_account_sliding {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.sitenav-account .site_account_panel_list .site_account_panel.is-selected {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__input-wrapper
  .form__field {
  display: block;
  padding: 14px 10px 3px;
  border-radius: 2px;
  border: 1px solid #d4d6d8;
  width: 100%;
  line-height: normal;
  height: 42px;
  color: #1e2d7d;
  -webkit-appearance: none;
  resize: none;
  font-size: 12px;
  outline: none;
  box-shadow: 0 1px rgba(212, 214, 216, 0.25) inset;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__input-wrapper
  .form__floating-label {
  position: absolute;
  left: 10px;
  top: 0;
  line-height: 42px;
  font-size: 12px;
  margin-bottom: 0;
  color: #677279;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  pointer-events: none;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__input-wrapper
  .form__field:focus
  + .form__floating-label,
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__input-wrapper
  .form__field.is-filled
  + .form__floating-label {
  -webkit-transform: translateY(-5px) scale(0.8);
  transform: translateY(-5px) scale(0.8);
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  form
  .form__submit {
  width: 100%;
  outline: none;
  padding: 0px 28px;
  height: 42px;
  line-height: 42px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  .site_account_secondary-action {
  margin-top: 16px;
  font-size: 12px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  .site_account_secondary-action
  > p {
  margin-bottom: 4px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_panel
  .site_account_inner
  .site_account_secondary-action
  > p
  .link {
  color: #096743;
  transition: color 0.2s ease-in-out;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  outline: none;
}
.sitenav-account .site_account_panel_list .site_account_info {
  text-align: left;
  font-size: 14px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul {
  margin: 0;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li {
  position: relative;
  margin-bottom: 5px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li.user-name {
  margin-top: -5px;
  margin-bottom: 8px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li:not(.user-name) {
  padding-left: 10px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li:not(.user-name):before {
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
  background: #333333;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li
  span {
  font-size: 15px;
  color: #282c2d;
  font-weight: 500;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li
  a {
  color: #333333;
  padding: 0 4px;
  margin-bottom: 10px;
}
.sitenav-account
  .site_account_panel_list
  .site_account_info
  .site_account_inner
  ul
  li
  a:hover {
  color: #dd0c1b;
}

.sitenav-overlay {
  background: rgba(225, 225, 225, 0.05);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms linear;
}

.mainHeader {
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
.mainHeader .flex-container-header {
  margin: 0 -15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.mainHeader .flex-container-header > div[class*="header-wrap-"] {
  padding: 0 15px;
}
.mainHeader .header-wrap-menu {
  -ms-align-self: center;
  align-self: center;
}
.mainHeader .header-wrap-menu .navbar-mainmenu {
  text-align: center;
}
.mainHeader .header-wrap-menu ul.menuList-primary {
  margin: 0;
  position: relative;
  z-index: 2;
}
.mainHeader .header-wrap-menu ul.menuList-primary > li {
  display: inline-block;
  margin: 0 5px;
  position: relative;
}
@media (max-width: 1349px) {
  .mainHeader .header-wrap-menu ul.menuList-primary > li {
    margin: 0 2px;
  }
}
.mainHeader .header-wrap-menu ul.menuList-primary > li > a {
  color: #333333;
  opacity: 0.85;
  position: relative;
  z-index: 2;
  display: block;
  padding: 25px 4px;
  width: 100%;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  -webkit-transition: none;
  transition: none;
}
@media (max-width: 1349px) {
  .mainHeader .header-wrap-menu ul.menuList-primary > li > a {
    padding: 15px 0;
  }
}
.mainHeader .header-wrap-menu ul.menuList-primary > li > a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  margin-left: 4px;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
}
@media (max-width: 1349px) {
  .mainHeader .header-wrap-menu ul.menuList-primary > li > a i {
    margin-left: 0;
  }
}
.mainHeader .header-wrap-menu ul.menuList-primary > li.active a,
.mainHeader .header-wrap-menu ul.menuList-primary > li.active a:focus {
  opacity: 1;
}
.mainHeader .header-wrap-menu ul.menuList-primary > li:hover > a,
.mainHeader .header-wrap-menu ul.menuList-primary > li.active:hover a {
  opacity: 1;
}
.mainHeader .header-wrap-menu ul.menuList-primary > li:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0%;
  transform-origin: 0%;
}
.mainHeader .header-wrap-menu ul.menuList-primary > li:hover > a i {
  opacity: 1;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.mainHeader
  .header-wrap-menu
  ul.menuList-primary
  li.has-submenu
  .menuList-submain {
  background: #fff;
  min-width: 225px;
  max-width: 180%;
  position: absolute;
  z-index: 99;
  top: 150%;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mainHeader
  .header-wrap-menu
  ul.menuList-primary
  li.has-submenu
  .menuList-submain
  ul.menuList-submain {
  left: 100%;
  top: 0;
}
.mainHeader
  .header-wrap-menu
  ul.menuList-primary
  li.has-submenu:hover
  > .menuList-submain {
  pointer-events: auto;
  visibility: visible;
  top: 100%;
  opacity: 1;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .mainHeader .header-wrap-menu ul.menuList-primary > li {
    margin: 0;
  }
  .mainHeader .header-wrap-menu ul.menuList-primary > li > a {
    font-size: 14px;
  }
}
.mainHeader .header-wrap-menu ul.menuList-submain li {
  position: relative;
}
.mainHeader .header-wrap-menu ul.menuList-submain li a {
  padding: 9px 18px;
  text-align: left;
  display: block;
  font-size: 14px;
  color: #333333;
  opacity: 0.85;
}
.mainHeader .header-wrap-menu ul.menuList-submain li a i {
  font-size: 9px;
  position: absolute;
  right: 15px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: right 0.3s ease-in-out;
  -ms-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
}
.mainHeader .header-wrap-menu ul.menuList-submain li a:hover i {
  right: 10px;
  opacity: 0.8;
}
.mainHeader .header-wrap-menu ul.menuList-submain li.has-submenu {
  padding-right: 30px;
}
.mainHeader .header-wrap-menu ul.menuList-submain li:not(:first-child) {
  border-top: 1px solid #ecf0f1;
}
.mainHeader .header-wrap-menu ul.menuList-submain li a:hover,
.mainHeader .header-wrap-menu ul.menuList-submain li a:hover i {
  opacity: 1;
}
.mainHeader .header-wrap-logo {
  -ms-align-self: center;
  align-self: center;
}
.mainHeader .header-wrap-logo .wrap-logo {
  padding: 8px 0;
  min-width: 150px;
  max-width: 210px;
}
.mainHeader .header-wrap-logo .wrap-logo img {
  max-height: 75px;
}
.mainHeader .header-wrap-logo h1,
.mainHeader .header-wrap-logo a {
  font-size: 25px;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0;
  color: #096743;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.mainHeader .header-wrap-action .header-action {
  position: relative;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.mainHeader .header-wrap-action .header-action .header-action-item {
  height: 100%;
  margin-left: 8px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text {
  position: relative;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .box-triangle {
  position: absolute;
  top: calc(100% - 15px);
  left: 0;
  right: 0;
  z-index: 992;
  margin: 0 auto;
  width: 22px;
  height: 15px;
  opacity: 0;
  visibility: visible;
  transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .box-triangle
  svg {
  -webkit-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.12));
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link {
  position: relative;
  display: block;
  font-size: 13px;
  color: #333333;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon,
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-text {
  display: inline-block;
  vertical-align: middle;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-text {
  padding-left: 2px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-text
  .txtbl {
  font-size: 14px;
  display: block;
  font-weight: 500;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-text
  .txt-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  max-width: 115px;
  overflow: hidden;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-text
  .fa {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  margin-left: 3px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon {
  position: relative;
  width: 35px;
  height: 35px;
  text-align: center;
  background: #e6e6e6;
  border-radius: 50%;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  svg {
  width: 13px;
  height: 35px;
  fill: #333333;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  svg.svg-ico-account {
  width: 14px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  svg.svg-ico-search {
  width: 13px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  .box-icon--close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.3);
  transition: opacity 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out,
    -webkit-transform 0.35s ease-in-out;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  .box-icon--close
  svg {
  width: 14px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link
  .box-icon
  .count-holder {
  display: inline-block;
  vertical-align: top;
  background: #096743;
  color: #fff;
  font-size: 11px;
  text-align: center;
  position: absolute;
  left: 23px;
  top: -6px;
  padding: 0px 3px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_text
  .header-action__link:hover
  svg.svg-ico-search {
  -ms-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_dropdown {
  position: absolute;
  top: 100%;
  left: auto;
  right: -15px;
  z-index: 990;
  min-width: 280px;
  border-radius: 3px;
  color: #677279;
  background: #fff;
  border: solid 1px #dfe3e8;
  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out,
    max-height 0s linear 0.25s, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    visibility 0.25s ease-in-out, max-height 0s linear 0.25s;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    visibility 0.25s ease-in-out, max-height 0s linear 0.25s,
    -webkit-transform 0.25s ease-in-out;
  will-change: transform;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_dropdown
  .box-triangle {
  position: absolute;
  bottom: calc(100% - 2px);
  margin: 0 auto;
  width: 22px;
  height: 15px;
  right: 19px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_dropdown
  .box-triangle
  svg {
  -webkit-filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(0 -3px 2px rgba(0, 0, 0, 0.12));
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_dropdown
  .header-dropdown_content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 20px 20px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item
  .header-action_dropdown
  .header-dropdown_content
  .sitenav-content
  .boxtitle {
  font-size: 18px;
  color: #096743;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 20px;
  font-weight: 500;
  padding-bottom: 8px;
  text-align: center;
  border-bottom: 1px solid var(--shop-color-border);
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline {
  margin-right: 30px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline
  .hotline--info {
  position: relative;
  display: block;
  font-size: 13px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline
  .hotline--info
  .box-icon {
  position: relative;
  margin-right: 12px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline
  .hotline--info
  .box-icon
  svg {
  width: 32px;
  height: 40px;
  fill: #096743;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline
  .hotline--info
  .box-text {
  font-size: 13px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_hotline
  .hotline--info
  .box-text
  .txtbl {
  color: #096743;
  display: block;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.4px;
}
@media (max-width: 1349px) and (min-width: 1200px) {
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_hotline {
    margin-right: 10px;
  }
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_order
  .btn-order {
  background: #096743;
  color: white;
  margin-left: 10px;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 20px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_order
  .btn-order
  svg {
  width: 22px;
  height: 35px;
  fill: white;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_order
  .btn-order:hover {
  color: white;
  outline: 0;
  background: #dd0c1b;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_order
  .btn-order:hover
  svg {
  fill: white;
}
@media (min-width: 1200px) {
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_order
    .btn-order
    svg {
    display: none;
  }
}
@media (max-width: 1199px) {
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_order
    .btn-order {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    margin-left: 4px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_order
    .btn-order
    span {
    display: none;
  }
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_search
  .header-action_dropdown
  .header-dropdown_content
  .ttbold {
  font-size: 17px;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 20px;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  border-bottom: 1px solid #ededed;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .header-action_dropdown
  .header-dropdown_content {
  padding-left: 0;
  padding-right: 0;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-ttbold {
  padding: 0 20px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-ttbold
  .ttbold {
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  font-weight: 500;
  padding: 6px 10px;
  background: #f3f5f6;
  border: 1px solid #e7ebec;
  text-align: center;
  border-radius: 4px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table {
  width: 100%;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr {
  border-bottom: 1px dotted #bcbcbc;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr:last-child {
  border: 0;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td {
  position: relative;
  border: none;
  vertical-align: top;
  border: none !important;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td:first-child {
  padding-left: 0px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td:last-child {
  padding-right: 0px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td.img {
  width: 65px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td.img
  img {
  width: 65px;
  border: 1px solid #ededed;
  max-width: none;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .pro-title {
  padding-right: 30px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .pro-title
  .pro-title-view {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .pro-title
  .variant {
  font-size: 12px;
  display: block;
  width: 100%;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .remove-cart {
  position: absolute;
  right: 0px;
  top: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  background: #e7ebec;
  line-height: 1.2;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .mini-cart_quantity {
  margin-top: 4px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .mini-cart_quantity
  .pro-quantity-view {
  font-size: 13px;
  font-weight: 600;
  color: #333;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .mini-cart_quantity
  .pro-quantity-view
  .qty-value {
  display: inline-block;
  height: 23px;
  line-height: 23px;
  min-width: 25px;
  padding: 0 3px;
  text-align: center;
  background: #f5f5f5;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(225, 227, 228, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table#cart-view
  tr
  td
  .mini-cart_quantity
  .pro-quantity-view
  .qty-btn {
  width: 30px;
  font-size: 20px;
  background: #f5f5f5;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table
  td
  .remove-cart
  svg {
  height: 20px;
  width: 8px;
  fill: #000;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table
  .item-cart_empty
  td {
  position: relative;
  padding: 0px;
  text-align: center;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table
  .item-cart_empty
  td
  .svgico-mini-cart {
  padding: 10px 0 5px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table
  .item-cart_empty
  td
  .svgico-mini-cart
  svg {
  width: 50px;
  height: 50px;
  margin: auto;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  table
  #total-view-cart {
  font-weight: 600;
  font-size: 16px;
  color: red;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .cart-view-scroll {
  padding: 0 20px;
}
@media (min-width: 992px) {
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .site-cart
    .cart-view
    .cart-view-scroll {
    overflow-y: scroll;
    max-height: 320px;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .site-cart
    .cart-view
    .cart-view-scroll::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .site-cart
    .cart-view
    .cart-view-scroll::-webkit-scrollbar-track {
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .site-cart
    .cart-view
    .cart-view-scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: #e1e3e4;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .site-cart
    .cart-view
    .cart-view-scroll::-webkit-scrollbar-thumb:window-inactive {
    background: #e1e3e4;
  }
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .cart-view-total {
  padding: 0 20px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .cart-view-total
  .table-total
  tr
  td {
  border: none !important;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .line {
  float: left;
  width: 100%;
  border-top: 1px solid #e1e3e4;
  margin: 10px 0px;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .linktocheckout,
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .linktocart {
  padding: 10px 15px;
  position: relative;
  width: 100%;
  white-space: nowrap;
  border-radius: 0;
  text-transform: uppercase;
  min-width: auto;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .linktocheckout {
  background: red;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_cart
  .site-cart
  .cart-view
  .linktocheckout:hover {
  background: red;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.js-action-show
  .header-action_text
  .box-triangle {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.1s;
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.js-action-show
  .header-action_text
  .header-action__link
  .box-icon
  .box-icon--close {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.js-action-show
  .header-action_text
  .header-action__link
  .box-icon
  > svg,
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.js-action-show
  .header-action_text
  .header-action__link
  .box-icon
  .count-holder {
  opacity: 0;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.js-action-show
  .header-action_dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1), visibility 0.4s linear,
    -webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
  transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
    transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear;
  transition: opacity 0.4s cubic-bezier(0, 1, 0.4, 1),
    transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1), visibility 0.4s linear,
    -webkit-transform 0.4s cubic-bezier(0.18, 1.25, 0.4, 1);
}
.mainHeader
  .header-wrap-action
  .header-action
  .header-action-item.header-action_account.js-action-show
  .header-action_dropdown
  .site_account_panel.is-selected {
  visibility: visible;
}
.mainHeader .header-wrap-action.header-wrap-iconav {
  display: none;
}
.mainHeader .header-wrap-action.header-wrap-iconav .header-action {
  position: initial;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item {
  margin-left: 0;
  margin-right: 0px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_backlink {
  display: none;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_backlink
  a {
  text-align: center;
  margin-right: 4px;
  width: 32px;
  line-height: 32px;
  color: #333333;
  height: 35px;
  display: block;
  font-size: 28px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon {
  width: 34px;
  height: 35px;
  background: transparent;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu {
  margin: auto;
  width: 25px;
  height: 35px;
  cursor: pointer;
  display: block;
  position: relative;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar:after,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar:before {
  width: 25px;
  height: 2px;
  background: #333333;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar {
  position: relative;
  top: 18px;
  display: block;
  margin: auto;
  -ms-transition: all 0ms 100ms, width 150ms linear;
  -webkit-transition: all 0ms 100ms, width 150ms linear;
  transition: all 0ms 100ms, width 150ms linear;
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 7px;
  -ms-transition: bottom 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
  -webkit-transition: bottom 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
  transition: bottom 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .hamburger-menu
  .bar:after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  -ms-transition: top 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
  -webkit-transition: top 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
  transition: top 300ms 100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1), width 150ms linear,
    background 150ms linear;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .box-icon
  .box-icon--close
  svg {
  width: 16px;
  height: 35px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu
  .header-action_dropdown {
  left: 0px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .header-action
  .header-action-item.header-action_menu.js-action-show
  .hamburger-menu {
  opacity: 0;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.mainHeader .header-wrap-action.header-wrap-iconav .site-menu.menu-mobile {
  position: relative;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap {
  height: 100%;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom {
  height: calc(100% - 10px);
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu {
  height: 100%;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  height: calc(100% - 0px);
  padding-bottom: 80px;
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  ul:not(.hidden-child) {
  display: block;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list.list-root {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li {
  position: relative;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  a {
  display: block;
  padding: 15px 15px 15px 0;
  margin-left: 15px;
  text-transform: uppercase;
  outline: none;
  border-bottom: 1px solid #eeeeee;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: background 0.3s, box-shadow 0.3s;
  -moz-transition: background 0.3s, box-shadow 0.3s;
  transition: background 0.3s, box-shadow 0.3s;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  a
  i {
  position: absolute;
  right: 10px;
  top: 50%;
  color: #757575 !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  a
  i
  svg {
  width: 8px;
  height: 12px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section {
  padding: 16px 20px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section
  .mobile-menu__section-title {
  font-size: 15px;
  color: #096743;
  line-height: 1.85;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section
  .mobile-menu__help-wrapper {
  color: #677279;
  display: flex;
  align-items: center;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section
  .mobile-menu__help-wrapper
  .icon--bi-phone {
  margin-right: 16px;
  width: 24px;
  height: 24px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section
  .mobile-menu__help-wrapper
  .icon--bi-email {
  margin-right: 18px;
  width: 22px;
  height: 22px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .vertical-menu-list
  li
  .mobile-menu__section
  .mobile-menu__help-wrapper
  a {
  border-bottom: 0 !important;
  text-transform: none !important;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li {
  position: relative;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li
  span,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li
  span,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li
  span {
  margin-right: 4px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li
  a,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li
  a,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li
  a {
  display: block;
  padding: 15px 15px 15px 0;
  margin-left: 15px;
  outline: none;
  border-bottom: 1px solid #e1e3e4;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: background 0.3s, box-shadow 0.3s;
  -moz-transition: background 0.3s, box-shadow 0.3s;
  transition: background 0.3s, box-shadow 0.3s;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li
  a
  b,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li
  a
  b,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li
  a
  b {
  font-weight: 700;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li:first-child
  a,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li:first-child
  a,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li:first-child
  a {
  color: #333333;
  background: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li:first-child
  a
  i,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li:first-child
  a
  i,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li:first-child
  a
  i {
  padding-right: 10px;
  font-weight: 500;
  font-size: 15px !important;
  color: #333333 !important;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li.level-2
  a
  i,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li.level-2
  a
  i,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li.level-2
  a
  i {
  position: absolute;
  right: 10px;
  top: 50%;
  color: #757575;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child
  li.level-2
  a
  i
  svg,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child
  li.level-2
  a
  i
  svg,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3
  li.level-2
  a
  i
  svg {
  width: 8px;
  height: 12px;
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .list-child.mm-opened,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child.mm-opened,
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child-3.mm-opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mainHeader
  .header-wrap-action.header-wrap-iconav
  .site-menu.menu-mobile
  .menu-mobile--wrap
  .menu-mobile--bottom
  .mp-menu
  .mplus-menu
  .sub-child.mm-sub {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mainHeader .header-search-mobile {
  display: none;
  position: relative;
  padding: 5px 6px;
  border-top: 1px solid #f5f5f5;
}
.mainHeader .header-search-mobile .searchform-mobile {
  position: relative;
}
.mainHeader .header-search-mobile .searchform-mobile input.input-search {
  width: 100%;
  padding: 7px 40px 7px 15px;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  background: #f5f5f5;
  color: #252a2b;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.mainHeader .header-search-mobile .searchform-mobile input.input-search:focus {
  outline: none;
}
.mainHeader .header-search-mobile .searchform-mobile .btn-search {
  width: 40px;
  height: 38px;
  min-width: auto;
  line-height: 38px;
  border: 0;
  outline: none;
  position: absolute;
  padding: 0;
  top: -2px;
  right: 0px;
  background: transparent;
}
.mainHeader .header-search-mobile .searchform-mobile .btn-search svg {
  width: 15px;
  height: 38px;
  line-height: 38px;
}
.mainHeader
  .header-search-mobile
  .searchform-mobile
  .btn-search
  span.search-close
  svg {
  fill: #3966b8;
  width: 20px;
}
.mainHeader
  .header-search-mobile
  .searchform-mobile
  .btn-search
  span.search-icon {
  display: block;
}
.mainHeader
  .header-search-mobile
  .searchform-mobile
  .btn-search
  span.search-close {
  display: none;
}
.mainHeader
  .header-search-mobile
  .searchform-mobile.expanded
  .btn-search
  span.search-icon {
  display: none;
}
.mainHeader
  .header-search-mobile
  .searchform-mobile.expanded
  .btn-search
  span.search-close {
  display: block;
}
.mainHeader .header-search-mobile .smart-search-wrapper {
  position: absolute;
  right: 0px;
  left: 0px;
  z-index: 100;
  top: calc(100% + 0px);
  width: initial;
  background: #fff;
  padding: 0 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}
.mainHeader .header-menu-desktop .wrap-logo-sticky {
  display: none;
}
.mainHeader.hSticky {
  visibility: hidden;
  opacity: 0;
}
.mainHeader.hSticky.hSticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.mainHeader.hSticky.hSticky-nav.hSticky-up {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.mainHeader.hSticky-nav .topbar {
  display: none;
}
.mainHeader.hSticky-nav .header-wrap-logo h1,
.mainHeader.hSticky-nav .header-wrap-logo a {
  font-size: 24px;
}
.mainHeader.hSticky-nav .header-wrap-logo .wrap-logo img {
  max-height: 60px;
}
@media (min-width: 768px) {
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_cart
    .header-action_dropdown {
    width: 420px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_search
    .header-action_dropdown {
    width: 420px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_account
    .header-action_dropdown {
    width: 360px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_menu
    .header-action_dropdown {
    width: 500px;
    height: 100vh;
  }
}
@media (max-width: 1200px) {
  .mainHeader .mainHeader-middle {
    position: relative;
  }
  .mainHeader .header-wrap-action.header-wrap-iconav {
    display: block;
  }
  .mainHeader .header-wrap-logo {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    text-align: left;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_menu
    .header-action_dropdown
    .header-dropdown_content {
    padding: 0 0px 80px;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_menu
    .header-action_dropdown
    .header-dropdown_content {
    overflow: auto;
    padding-bottom: 120px;
    -webkit-overflow-scrolling: touch;
  }
  .mainHeader .flex-container-header .header-wrap-action .header-action__link {
    padding-top: 14px;
    padding-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .mainHeader .header-search-mobile {
    display: block;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item.header-action_search {
    display: none;
  }
  .mainHeader.hSticky-nav
    .header-wrap-action
    .header-action
    .header-action-item.header-action_order {
    display: none;
  }
  .mainHeader.hSticky-nav .header-search-mobile {
    /*position: absolute;right: 0;left: 0;top: 50%;
                  -webkit-transform: translateY(-50%);transform: translateY(-50%);
                  visibility: hidden;	padding:2px 98px 0px 56px;*/
    display: none;
  }
  .mainHeader.hSticky-nav .header-search-mobile .searchform-mobile {
    position: relative;
    visibility: visible;
    margin: 0 auto;
  }
  .mainHeader.hSticky-nav .header-search-mobile .smart-search-wrapper {
    visibility: visible;
    top: 100%;
  }
  .mainHeader.hSticky-nav
    .header-wrap-action
    .header-action
    .header-action-item.header-action_search {
    display: block;
  }
  .mainHeader.hSticky-nav.mainHeader-product .header-search-mobile {
    display: none;
  }
  .mainHeader.hSticky-nav.mainHeader-product
    .header-wrap-action
    .header-action
    .header-action-item.header-action_backlink {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .mainHeader.hSticky-nav.mainHeader-product
    .header-wrap-action
    .header-action
    .header-action-item.header-action_menu
    .header-action_dropdown
    .box-triangle {
    left: 44px;
  }
}
@media (max-width: 767px) {
  .mainHeader
    .flex-container-header
    > div[class*="header-wrap-"]:not(.header-wrap-action) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .mainHeader .flex-container-header .header-wrap-action .header-action__link {
    padding-top: 12px;
    padding-bottom: 10px;
  }
  .mainHeader .flex-container-header > div.header-wrap-logo {
    padding-left: 0;
    padding-right: 0;
  }
  .mainHeader .flex-container-header > div.header-wrap-iconav {
    padding-left: 10px;
  }
  .mainHeader .header-wrap-logo .wrap-logo {
    padding: 0;
  }
  .mainHeader .header-wrap-logo .wrap-logo img {
    max-height: 60px;
    max-width: 100%;
  }
  .mainHeader .header-wrap-logo h1,
  .mainHeader .header-wrap-logo a {
    font-size: 20px;
  }
  .mainHeader .header-wrap-action .header-action {
    position: initial;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item
    .header-action_dropdown {
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .mainHeader
    .header-wrap-action
    .header-action
    .header-action-item
    .header-action_dropdown
    .header-dropdown_content {
    overflow: auto;
    padding-bottom: 220px;
    -webkit-overflow-scrolling: touch;
  }
  .mainHeader.hSticky-nav .header-wrap-logo h1,
  .mainHeader.hSticky-nav .header-wrap-logo a {
    font-size: 20px;
  }
}

.mainHeader .topbar {
  padding: 8px 0;
  font-weight: 500;
  text-align: center;
  background: #096743;
  color: #ffffff;
}

/* ---------------------  search sidebar --------------------- */
.site_search .searchform {
  position: relative;
  padding: 0;
  margin-bottom: 15px;
}

.site_search .searchinput {
  background: #f5f5f5;
  border: 1px solid #ececec;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  width: 100%;
  height: 45px;
  padding: 0 55px 0 20px;
  margin: 0;
  display: inline-block;
  border-radius: 0;
  transition: all 150ms linear;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.site_search .btn-search {
  width: 45px;
  height: 45px;
  line-height: 45px;
  position: absolute;
  padding: 0;
  top: -2px;
  right: 0px;
  background: transparent;
  transition: opacity 150ms linear;
  border: none;
  min-width: auto;
  outline: none;
  box-shadow: none;
}
.site_search .btn-search:focus {
  outline: none;
}
.site_search .btn-search svg {
  width: 18px;
  height: 45px;
  opacity: 0.4;
}

.site_search input:focus {
  background: #ffffff;
  outline: none;
  border-color: #ef233c;
}

/*----------------- Search auto ---------------*/
.smart-search-wrapper {
  width: 100%;
  z-index: 100;
}
.smart-search-wrapper .item-ult {
  padding: 10px 10px;
  border-bottom: 1px dotted #dfe0e1;
  clear: both;
}
.smart-search-wrapper .item-ult .thumbs {
  width: 40px;
  display: inline-block;
  text-align: right;
}
.smart-search-wrapper .item-ult .title {
  width: calc(100% - 40px);
  padding-right: 5px;
  float: left;
  line-height: 20px;
  position: relative;
  margin-top: 0px !important;
}
.smart-search-wrapper .item-ult .title a {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  float: left;
  width: 100%;
  margin-bottom: 4px;
}
.smart-search-wrapper .item-ult .title p {
  line-height: 15px;
  font-size: 12px;
  font-weight: 600;
  float: left;
  margin: 0;
}
.smart-search-wrapper .item-ult .title del {
  font-size: 11px;
  font-weight: 300;
  margin-left: 5px;
  color: #797979;
}
.smart-search-wrapper .resultsMore {
  width: 100%;
  float: left;
}
.smart-search-wrapper .resultsMore a {
  text-align: center;
  display: block;
  font-size: 14px;
  padding: 10px 0;
}
.smart-search-wrapper .dataEmpty {
  text-align: center;
  padding: 10px 0;
  margin: 0;
}

/* - Mini box Account - */
.site_account {
  text-align: center;
  /* Login Phone */
}
.site_account .site_account_panel_list {
  position: relative;
  height: 100%;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
.site_account .site_account_panel_list .site_account_inner {
  font-size: 12px;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  form
  .form__input-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  form
  .form__input-wrapper
  .form__field {
  display: block;
  padding: 14px 10px 3px;
  border-radius: 2px;
  border: 1px solid #d4d6d8;
  width: 100%;
  line-height: normal;
  height: 42px;
  color: #1e2d7d;
  -webkit-appearance: none;
  resize: none;
  font-size: 12px;
  outline: none;
  box-shadow: 0 1px rgba(212, 214, 216, 0.25) inset;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  form
  .form__input-wrapper
  .form__field:focus
  + .form__floating-label {
  -webkit-transform: translateY(-6px) scale(0.8);
  transform: translateY(-6px) scale(0.8);
}
.site_account
  .site_account_panel_list
  .site_account_inner
  form
  .form__input-wrapper
  .form__field.is-filled
  + .form__floating-label {
  -webkit-transform: translateY(-6px) scale(0.8);
  transform: translateY(-6px) scale(0.8);
}
.site_account
  .site_account_panel_list
  .site_account_inner
  form
  .form__input-wrapper
  .form__floating-label {
  position: absolute;
  left: 10px;
  top: 0;
  line-height: 42px;
  font-size: 12px;
  margin-bottom: 0;
  color: #677279;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: left top;
  transform-origin: left top;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  pointer-events: none;
}
.site_account .site_account_panel_list .site_account_inner form .form__submit {
  min-width: auto;
  border-radius: 0;
  width: 98%;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  .site_account_secondary-action {
  margin-top: 16px;
  font-size: 12px;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  .site_account_secondary-action
  > p {
  margin-bottom: 4px;
}
.site_account
  .site_account_panel_list
  .site_account_inner
  .site_account_secondary-action
  > p
  .link {
  color: #ef233c;
  transition: color 0.2s ease-in-out;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  outline: none;
}
.site_account
  .site_account_panel_list
  .site_account_header
  .site_account_title {
  margin-bottom: 4px;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}
.site_account .site_account_panel_list .site_account_info {
  text-align: left;
  font-size: 14px;
}
.site_account .site_account_panel_list .site_account_info .site_account_header {
  text-align: center;
  border-bottom: 1px solid #ededed;
  padding: 0 0 10px;
  margin-bottom: 12px;
}
.site_account
  .site_account_panel_list
  .site_account_info
  .site_account_header
  .site_account_title {
  margin-bottom: 0;
}
.site_account .site_account_panel_list .site_account_info ul {
  margin: 0;
}
.site_account .site_account_panel_list .site_account_info ul li {
  padding: 0 4px;
  margin-bottom: 10px;
}
.site_account .site_account_panel_list .site_account_info ul li span {
  font-size: 15px;
  color: #282c2d;
  font-weight: 500;
}
.site_account .site_account_panel_list .site_account_info ul li a:hover {
  color: #dd0c1b;
}
.site_account .site_account_panel_list .site_account_panel {
  width: 100%;
  visibility: hidden;
  transition: visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
    visibility 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
    visibility 500ms cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
}
.site_account
  .site_account_panel_list
  .site_account_panel.site_account_default {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.site_account
  .site_account_panel_list
  .site_account_panel.site_account_default.is-selected {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.site_account
  .site_account_panel_list
  .site_account_panel.site_account_sliding {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.site_account
  .site_account_panel_list
  .site_account_panel.site_account_sliding.is-selected {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.site_account .action-form-login .method-login-mini-account {
  margin: 0 0 5px 0;
}
.site_account .action-form-login .method-login-mini-account label {
  padding: 0 0 0 5px;
  cursor: pointer;
}
.site_account #login-phone-mini-cart #phone_number,
.site_account #login-phone-mini-cart #otp_code {
  display: block;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #d4d6d8;
  width: 100%;
  line-height: normal;
  height: 42px;
  color: #1e2d7d;
  -webkit-appearance: none;
  resize: none;
  font-size: 12px;
  outline: none;
  box-shadow: 0 1px rgba(212, 214, 216, 0.25) inset;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
}
.site_account #login-phone-mini-cart #phone-auth-submit {
  min-width: auto;
  border-radius: 0;
}
.site_account #login-phone-mini-cart .action_bottom > div {
  display: none;
}
.site_account #login-phone-mini-cart .action_bottom button {
  min-width: auto;
  border-radius: 0;
  width: 49%;
  padding: 10px 0;
}

.header-action_account.js-action-show
  .site_account
  .site_account_panel_list
  .site_account_panel.is-selected {
  visibility: visible;
}

@keyframes scroll-in {
  0% {
    top: 2px;
  }
  49% {
    top: -14px;
  }
  50% {
    top: 19px;
  }
  100% {
    top: 2px;
  }
}

@keyframes scroll-out {
  0% {
    top: 2px;
  }
  49% {
    top: 19px;
  }
  50% {
    top: -14px;
  }
  100% {
    top: 2px;
  }
}

.locked-scroll.locked-scroll-menu {
  overflow: hidden;
  width: 100%;
  top: 0;
}

.locked-scroll .mainHeader {
  position: relative;
  z-index: 998;
}

.locked-scroll .sitenav-overlay {
  opacity: 1;
  visibility: visible;
}

.mainBody-theme.body-scroll .mainHeader.hSticky.hSticky-nav.hSticky-up {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

@media (max-width: 991px) {
  .locked-scroll {
    overflow: hidden;
    width: 100%;
    top: 0;
  }
  .locked-scroll .mainHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .locked-scroll .mainHeader.hSticky.hSticky-nav.hSticky-up,
  .locked-scroll .mainHeader.hSticky.hSticky-nav {
    opacity: 1;
    visibility: visible;
    z-index: 998;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: z-index 0s;
    transition: z-index 0s;
  }
}

/*---------------------- Sroll to Top -------------------*/
.back-to-top {
  position: fixed;
  right: 10px;
  bottom: 15px;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
}
.back-to-top.show {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.back-to-top .btt-label-back {
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  -webkit-transform: rotate(-90deg) translateX(50%) translateY(50%);
  -moz-transform: rotate(-90deg) translateX(50%) translateY(50%);
  transform: rotate(-90deg) translateX(50%) translateY(50%);
}
.back-to-top .btt-icon-back {
  position: absolute;
  bottom: 250%;
  left: 80%;
  -webkit-transform: translateX(-50%) translateY(0);
  -moz-transform: translateX(-50%) translateY(0);
  transform: translateX(-50%) translateY(0);
  -webkit-transition: -webkit-transform 0.45s cubic-bezier(0, 0.95, 0.77, 1);
  transition: transform 0.45s cubic-bezier(0, 0.95, 0.77, 1);
  will-change: transform;
}

/* INDEX */
.section-index-slider + .section-index-service {
  margin-top: -85px;
}
@media (max-width: 767px) {
  .section-index-slider + .section-index-service {
    margin-top: 0;
  }
}

.section-index-slider .slider-item {
  position: relative;
  text-align: center;
}
.section-index-slider .slider-item--image {
  width: 100%;
}
.section-index-slider .slider-item--caption {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
@media (max-width: 991.9px) {
  .section-index-slider .slider-item--caption {
    width: 100%;
    padding: 0 15px;
  }
}
.section-index-slider .slider-item--caption .banner--text-1 {
  opacity: 0;
  font-size: 24px;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
@media (max-width: 991.9px) {
  .section-index-slider .slider-item--caption .banner--text-1 {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 20px 0;
  }
}
.section-index-slider .slider-item--caption .banner--text-2 {
  opacity: 0;
  font-size: 56px;
  color: white;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
@media (max-width: 991.9px) {
  .section-index-slider .slider-item--caption .banner--text-2 {
    font-size: 34px;
    line-height: 1.2;
    margin: 0 0 20px 0;
  }
}
.section-index-slider .slider-item--caption .banner--text-3 {
  opacity: 0;
  font-size: 18px;
  color: white;
  letter-spacing: 0.8px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
@media (max-width: 991.9px) {
  .section-index-slider .slider-item--caption .banner--text-3 {
    font-size: 14px;
    line-height: 19px;
  }
}
.section-index-slider .slider-item--caption .banner--action {
  opacity: 0;
  margin: 30px 0 0 0;
}
.section-index-slider .slider-item--caption .banner--action a {
  display: inline-block;
  min-width: 180px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 16px;
  padding: 14px 15px;
  margin: 0 10px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
  color: #ffffff;
  background-color: #096743;
  border-color: #096743;
  font-weight: bold;
  text-transform: uppercase;
}
.section-index-slider .slider-item--caption .banner--action a:hover {
  background: black;
  color: white;
}

.section-index-slider .owl-nav button.owl-prev,
.section-index-slider .owl-nav button.owl-next {
  position: absolute;
  opacity: 0;
  top: 42%;
  padding: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.31);
  color: #ffffff;
  outline: none;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}
.section-index-slider .owl-nav button.owl-prev i,
.section-index-slider .owl-nav button.owl-next i {
  font-size: 16px;
  line-height: 50px;
}
.section-index-slider .owl-nav button.owl-prev:hover,
.section-index-slider .owl-nav button.owl-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: #333333;
}

.section-index-slider .owl-nav button.owl-prev {
  left: 40px;
}

.section-index-slider .owl-nav button.owl-next {
  right: 40px;
}

@media (min-width: 1200px) {
  .section-index-slider:hover .owl-nav button {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.section-index-slider .owl-item.active .slider-item--caption .banner--text-1 {
  animation: 0.5s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.15s 1 forwards
    slideshowFade;
}

.section-index-slider .owl-item.active .slider-item--caption .banner--text-2 {
  animation: 1s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.3s 1 forwards
    slideshowFade;
}

.section-index-slider .owl-item.active .slider-item--caption .banner--text-3 {
  animation: 1s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.3s 1 forwards
    slideshowFade;
}

.section-index-slider .owl-item.active .slider-item--caption .banner--action {
  animation: 1s cubic-bezier(0.46, 0.03, 0.52, 0.96) 0.3s 1 forwards
    slideshowFade;
}

.section-index-slider .owl-dots {
  margin: 15px 0 0 0;
  position: absolute;
  bottom: 25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.section-index-slider .owl-dots .owl-dot {
  opacity: 0.7;
  outline: none;
  box-shadow: none;
  border: none;
}
.section-index-slider .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #096743;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}
.section-index-slider .owl-dots .owl-dot.active {
  opacity: 1;
}
.section-index-slider .owl-dots .owl-dot.active span {
  background: #096743;
}

.section-index-service {
  position: relative;
  z-index: 9;
  padding-bottom: 125px;
  margin-top: 80px;
}
.section-index-service .listing-service {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
}
.section-index-service .listing-service .item-service {
  position: relative;
  padding: 35px 15px;
  text-align: center;
  transition: all 0.5s ease;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.section-index-service .listing-service .item-service .box-thumb {
  margin: 0 0 15px 0;
}
.section-index-service
  .listing-service
  .item-service
  .box-thumb
  .lazyload-thumb {
  position: relative;
  display: inline-block;
}
.section-index-service
  .listing-service
  .item-service
  .box-thumb
  .lazyload-thumb__placeholder {
  position: absolute;
  width: 54%;
  height: 54%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border: none;
  overflow: hidden;
}
.section-index-service
  .listing-service
  .item-service
  .box-thumb
  .lazyload-thumb
  svg {
  height: 96px;
  fill: #e9e8e9;
  stroke: transparent;
  transition: all 0.5s ease;
}
.section-index-service .listing-service .item-service .box-info {
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
  font-size: 16px;
  color: #333333;
}
.section-index-service .listing-service .item-service .box-link {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: none;
}
.section-index-service .listing-service .item-service:hover {
  background: #ebe117;
}
.section-index-service .listing-service .item-service:hover .box-thumb img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.section-index-service .listing-service .item-service:hover .box-thumb svg {
  fill: #dd0c1b;
}
@media (max-width: 991px) and (min-width: 768px) {
  .section-index-service {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section-index-service {
    padding-bottom: 0;
    padding: 40px 0 20px 0;
  }
  .section-index-service .listing-service .item-service {
    -ms-flex: 0 0 16.6666%;
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  }
}

.section-index-aboutus {
  padding: 80px 0;
  background: #fcfcfc;
}
@media (max-width: 991px) {
  .section-index-aboutus {
    padding: 50px 0;
  }
}
.section-index-aboutus .aboutus-inner-image {
  position: relative;
}
.section-index-aboutus .aboutus-inner-image img {
  border-radius: 10px;
  width: 100%;
}
.section-index-aboutus .aboutus-inner-content .svg-insert {
  content: "";
  position: absolute;
  z-index: -1;
  opacity: 0.06;
  left: 15%;
  bottom: calc(100% + 60px);
  transform: rotate(155deg);
  -ms-transform: rotate(155deg);
  width: 45px;
  height: 45px;
}
.section-index-aboutus .aboutus-inner-content:after {
  content: "";
  width: 50px;
  height: 50px;
  border: 7px solid #096743;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.08;
  right: calc(100% + 1px);
  top: 20%;
}
.section-index-aboutus .aboutus-inner-content .section-content-info p {
  margin: 0 0 20px 0;
}
.section-index-aboutus .aboutus-inner-content .section-content-info ul {
  list-style: inherit;
  padding: 0 0 0 20px;
}
.section-index-aboutus .aboutus-inner-content .section-content-info ul li {
  margin: 0 0 20px 0;
}
@media (min-width: 1200px) {
  .section-index-aboutus .aboutus-inner-content {
    padding: 0 30px 0 0;
  }
}
@media (max-width: 767px) {
  .section-index-aboutus .aboutus-inner-image {
    /*margin: 0 0 20px 0;*/
  }
}

.section-index-counters {
  position: relative;
  z-index: 1;
  padding: 100px 0;
  background: #0b1121
    url(//theme.hstatic.net/200000299178/1000739054/14/home_counters_bkg.jpg?v=76);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.section-index-counters:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.7);
}
.section-index-counters .list-counters {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
}
.section-index-counters .list-counters .counters-item {
  text-align: center;
  padding: 0 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
@media (max-width: 991px) {
  .section-index-counters .list-counters .counters-item {
    flex-basis: auto;
    width: 100%;
  }
  .section-index-counters .list-counters .counters-item ~ .counters-item {
    margin: 30px 0 0 0;
  }
}
.section-index-counters .list-counters .counters-item .counters-img {
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 80px;
}
.section-index-counters .list-counters .counters-item .counters-info {
  text-align: left;
  padding-left: 20px;
  max-width: calc(100% - 80px);
}
.section-index-counters .list-counters .counters-item .counters-info .value {
  margin: 0;
  color: white;
  font-size: 36px;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.section-index-counters .list-counters .counters-item .counters-info p {
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
  font-size: 15px;
  margin: 0;
  color: white;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .section-index-counters {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  .section-index-counters {
    padding: 30px 0;
  }
}

/* End -- INDEX */
/* Main Footer */
#section-main-footer {
  background: #ffffff;
}
#section-main-footer .footer-container {
  padding: 50px 0 30px;
}
#section-main-footer .footer-container .footer-top-logo {
  margin-bottom: 20px;
}
#section-main-footer .footer-container .footer-top-logo .footer-logo a {
  font-size: 30px;
  color: #333333;
  display: block;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
#section-main-footer .footer-container .footer-top-logo .footer-logo img {
  max-height: 60px;
}
#section-main-footer .footer-container .footer-top-logo .wrap-logo-text {
  margin: 0 0 0 20px;
}
#section-main-footer
  .footer-container
  .footer-top-logo
  .wrap-logo-text
  .wrap-logo-text-1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 2px;
  margin: 0;
}
#section-main-footer
  .footer-container
  .footer-top-logo
  .wrap-logo-text
  .wrap-logo-text-1
  a {
  color: #ffffff;
}
#section-main-footer
  .footer-container
  .footer-top-logo
  .wrap-logo-text
  .wrap-logo-text-2 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0px;
  margin: 0;
}
#section-main-footer
  .footer-container
  .footer-top-logo
  .wrap-logo-text
  .wrap-logo-text-2
  a {
  color: #ffffff;
}
#section-main-footer .footer-container .footer-top-description {
  color: #707070;
}
#section-main-footer .footer-container .footer-top-description .wrap-logo-bct {
  margin-top: 20px;
}
#section-main-footer
  .footer-container
  .footer-top-description
  .wrap-logo-bct
  img {
  max-width: 160px;
}
#section-main-footer .footer-container .footer-accordion-item .footer-title {
  position: relative;
}
#section-main-footer .footer-container .footer-accordion-item .footer-title h3 {
  color: #333333;
  font-weight: 500;
  margin: 10px 0 20px 0;
  line-height: 1.5;
  text-transform: uppercase;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-title--icon {
  display: none;
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  text-align: center;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-title--icon
  svg {
  display: block;
  height: 20px;
  margin: 0 auto;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1),
    color 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-title--icon
  svg
  .icon-chevron-down-left,
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-title--icon
  svg
  .icon-chevron-down-right {
  transform: rotate(0);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 50% 93%;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-content
  ul {
  margin: 0;
  padding: 0;
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-content
  ul
  li {
  width: 100%;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-content
  ul
  li
  a {
  padding: 0px 0 10px 0;
  color: #707070;
  display: block;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-content
  ul
  li
  a:hover {
  color: #dd0c1b;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-social
  ul
  li {
  background: #096743;
  margin: 0 5px 0 0;
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  justify-content: center;
  border-radius: 2px;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-social
  ul
  li:hover {
  background: #dd0c1b;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-social
  ul
  li
  a {
  color: white;
}
#section-main-footer
  .footer-container
  .footer-accordion-item
  .footer-social
  ul
  li
  a
  i {
  font-size: 16px;
}
#section-main-footer .footer-copyright {
  padding: 20px 0;
  border-top: 1px solid #ededed;
}
@media (max-width: 991.9px) {
  #section-main-footer .footer-copyright {
    padding: 20px 0 60px 0;
  }
}
#section-main-footer .footer-copyright .copyright {
  text-align: center;
  color: #333333;
  font-weight: 300;
}
#section-main-footer .footer-copyright .copyright p {
  margin: 0;
}
#section-main-footer .footer-copyright .copyright p a {
  color: #096743;
}
#section-main-footer .footer-copyright .copyright p a:hover {
  color: #dd0c1b;
}
@media (min-width: 768px) {
  #section-main-footer .footer-container .item-col-footer {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  #section-main-footer .footer-container .item-col-footer.large-col {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  #section-main-footer .footer-container .item-col-footer {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #section-main-footer .footer-container .item-col-footer.large-col {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #section-main-footer
    .footer-container
    .item-col-footer:nth-child(2n)
    ~ .item-col-footer {
    margin: 30px 0 0 0;
  }
  #section-main-footer .footer-container .footer-top-logo .footer-logo img {
    max-height: 45px;
  }
  #section-main-footer .footer-container .footer-top-logo .wrap-logo-text {
    margin-left: 12px;
  }
  #section-main-footer
    .footer-container
    .footer-top-logo
    .wrap-logo-text
    .wrap-logo-text-1 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  #section-main-footer .footer-container {
    padding-bottom: 20px;
  }
  #section-main-footer .footer-container .footer-top-logo .footer-logo img {
    max-height: 45px;
    max-width: 140px;
  }
  #section-main-footer .footer-container .footer-top-description {
    margin-bottom: 35px;
  }
  #section-main-footer
    .footer-container
    .footer-accordion-item
    .footer-title
    h3 {
    padding-right: 30px;
    margin-top: 0;
  }
  #section-main-footer
    .footer-container
    .footer-accordion-item
    .footer-title--icon {
    display: block;
  }
  #section-main-footer
    .footer-container
    .footer-accordion-item.active-toggle
    .footer-title--icon
    svg {
    transform: translateY(-22%);
  }
  #section-main-footer
    .footer-container
    .footer-accordion-item.active-toggle
    .footer-title--icon
    svg
    .icon-chevron-down-left {
    transform: rotate(45deg);
  }
  #section-main-footer
    .footer-container
    .footer-accordion-item.active-toggle
    .footer-title--icon
    svg
    .icon-chevron-down-right {
    transform: rotate(-45deg);
  }
  #section-main-footer .footer-container .footer-content,
  #section-main-footer .footer-container .footer-social {
    display: none;
    padding-bottom: 20px;
  }
}

/* End Main Footer */
/* Section Banner Page Order */
#section-order-slider {
  position: relative;
  overflow: hidden;
}
#section-order-slider:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(15, 9, 48, 0.6);
}
#section-order-slider .inner-section-slider.text-center .group-breadcrumbs {
  position: absolute;
  top: 60%;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
#section-order-slider .inner-section-slider.text-center .group-breadcrumbs h1 {
  color: white;
  font-size: 28px;
  margin: 0 0 10px 0;
}
#section-order-slider .inner-section-slider.text-center .group-breadcrumbs a {
  color: white;
}
#section-order-slider
  .inner-section-slider.text-center
  .group-breadcrumbs
  span {
  color: white;
}
#section-order-slider
  .inner-section-slider.text-center
  .group-breadcrumbs
  .hr-span {
  margin: 0 10px;
}

/* End Section Banner Page Order */
/* Content Page Order */
.orders-book__center {
  text-align: center;
  margin: 20px 0;
}
.orders-book__center button {
  padding: 10px 16p;
  text-align: center;
  font-weight: 600;
  background: #ff443c;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  border: none;
}

.order-item {
  padding: 20px;
  border-bottom: 1px dotted var(--shop-color-border);
  cursor: pointer;
}
.order-item--inner {
  display: flex;
  display: -moz-flex;
  display: -o-flex;
  display: -webkit-flex;
}
.order-item--inner .order-image {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.order-item--inner .order-info {
  width: calc(100% - 100px);
  padding-left: 20px;
}
.order-item--inner .order-info h3 {
  font-size: 15px;
  line-height: 1.5;
  margin: 0 0 5px 0;
}
.order-item--inner .order-info h3 a {
  color: #333333;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.order-item--inner .order-info h3:hover {
  color: #dd0c1b;
}
.order-item--inner .order-info .odi-desc {
  font-size: 13px;
  margin: 0 0 8px;
}
@media (max-width: 1024px) {
  .order-item--inner .order-info .odi-desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.order-item--inner .order-info .odi-price {
  margin: 0;
}
.order-item--inner .order-info .odi-price span {
  font-size: 15px;
  color: #db1a2a;
  margin-right: 5px;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.order-item--inner .order-info .odi-price del {
  font-size: 13px;
  color: gray;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
}
.order-item--inner .order-info .odi-soldout {
  margin: 0;
  background: #e7e7e7;
  color: #333333;
  padding: 4px 10px;
  border-radius: 4px;
  margin-right: 13px;
  font-size: 14px;
}
@media (max-width: 560px) {
  .order-item--inner .order-info .odi-soldout {
    margin: 10px 10px 0 0;
  }
  .order-item--inner .order-info .odi-soldout ~ button {
    margin: 10px 0 0 0;
  }
}
.order-item--inner .order-info .odi-quantity {
  position: relative;
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.order-item--inner .order-info .odi-quantity .btn-odiqty {
  background: #ffffff;
  border: 1px solid #096743;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
}
.order-item--inner .order-info .odi-quantity .btn-odiqty svg {
  stroke: #096743;
  fill: #096743;
  height: 28px;
  width: 13px;
}
.order-item--inner .order-info .odi-quantity .btn-odiqty.odiqty-minus {
  display: none;
  transition: all 0.4s;
}
.order-item--inner .order-info .odi-quantity .btn-odiqty.disabled {
  pointer-events: none;
  opacity: 0.35;
}
.order-item--inner .order-info .odi-quantity .btn-odivalue {
  display: none;
  transition: all 0.4s;
  padding: 0;
  text-align: center;
  width: 40px;
  height: 30px;
  line-height: 30px;
  border: 0;
  font-weight: 500;
  font-size: 18px;
  color: #333;
  background: #e8f6fe;
  border-radius: 4px;
  margin: 0 10px 0 0;
}
.order-item--inner .order-info .odi-quantity:not(.js-show) .btn-odiqty {
  border-color: #096743;
  background: #096743;
}
.order-item--inner .order-info .odi-quantity:not(.js-show) .btn-odiqty svg {
  stroke: #fff;
  fill: #fff;
}
.order-item--inner .order-info .odi-quantity.js-show .btn-odivalue {
  display: block;
}
.order-item--inner .order-info .odi-quantity.js-show .btn-odiqty.odiqty-minus {
  display: none;
}
.order-item.empty-product {
  text-align: center;
}
.order-item.empty-product p {
  padding: 20px 0;
  margin: 0;
}

.orders-block__list.orders-block__tags .section-loading {
  padding: 100px 0 120px;
}
.orders-block__list.orders-block__tags .section-loading:before {
  content: "";
  border: 4px dashed #ececec;
  border-top: 6px dashed #096743;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
  opacity: 0.8;
  animation: rotateIconLoad 0.8s linear infinite;
}

.orders-block__list .order-item:last-child {
  border-bottom: none;
}

.section-index-listmenu {
  padding: 80px 0;
  background: var(--shop-color-bgindex);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.section-index-listmenu .listmenu-heading {
  text-align: center;
  margin-bottom: 40px;
}
.section-index-listmenu .listmenu-heading h2 {
  font-size: 36px;
  margin: 0;
  font-weight: 500;
}
.section-index-listmenu .listmenu-content .orders-cate,
.section-index-listmenu .listmenu-content .orders-cart {
  position: sticky;
  top: 75px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
.section-index-listmenu .listmenu-content .orders-cate {
  padding: 20px;
  background: #fff;
  border: 1px solid var(--shop-color-border);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
}
.section-index-listmenu .listmenu-content .orders-cate ul {
  margin: 0;
  padding: 0;
}
.section-index-listmenu .listmenu-content .orders-cate ul li {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dotted var(--shop-color-border);
}
.section-index-listmenu .listmenu-content .orders-cate ul li a {
  display: block;
  padding: 10px 0;
  line-height: 1.2;
  color: #333333;
  font-weight: 600;
}
.section-index-listmenu .listmenu-content .orders-cate ul li a:hover {
  color: #333;
}
.section-index-listmenu .listmenu-content .orders-cate ul li:last-child {
  margin: 0;
  border: none;
  padding: 0;
}
.section-index-listmenu .listmenu-content .orders-cate ul li.active {
  border-color: #db1a2a;
}
.section-index-listmenu .listmenu-content .orders-cate ul li.active a {
  color: #db1a2a;
}
@media (max-width: 1199px) {
  .section-index-listmenu .listmenu-content .orders-cate {
    top: 125px;
  }
}
@media (max-width: 992px) {
  .section-index-listmenu .listmenu-content .orders-cate {
    /*top: var(--header-height);*/
    top: 0;
    width: 100%;
    margin: 0;
    left: 0;
    box-shadow: none;
    border: none;
    border-top: 1px solid #ddd;
    overflow-x: auto;
    z-index: 8;
    padding: 8px 0;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
    border-radius: 0;
    -webkit-border-radius: 0;
  }
  .section-index-listmenu .listmenu-content .orders-cate.orders-fixed {
    position: fixed;
    border-bottom: 1px solid #ddd;
  }
  .section-index-listmenu .listmenu-content .orders-cate ul {
    white-space: nowrap;
    margin: 0;
    transition: all 0.3s ease;
    transform: translateX(0);
  }
  .section-index-listmenu .listmenu-content .orders-cate ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;
  }
  .section-index-listmenu .listmenu-content .orders-cate ul li a {
    font-size: 14px;
    padding: 5px 10px;
  }
}
.section-index-listmenu .listmenu-content .orders-product .search-product {
  position: relative;
}
.section-index-listmenu
  .listmenu-content
  .orders-product
  .search-product
  input {
  width: 100%;
  border: 0 none;
  padding: 0 10px 0 40px;
  line-height: 40px;
  background: #fff;
  color: #333;
  border: 1px solid #7f7f7f;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.section-index-listmenu
  .listmenu-content
  .orders-product
  .search-product
  label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-index-listmenu
  .listmenu-content
  .orders-product
  .search-product
  label
  svg {
  stroke: #666;
  fill: #666;
}
.section-index-listmenu .listmenu-content .orders-product .orders-block {
  background: #fff;
  border: 1px solid var(--shop-color-border);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
  margin-bottom: 40px;
}
@media (max-width: 991.9px) {
  .section-index-listmenu .listmenu-content .orders-product .orders-block {
    border-radius: 0;
    -webkit-border-radius: 0;
    overflow: hidden;
    margin-bottom: 15px;
    border: 1px solid #ececec;
    border-right: none;
    border-left: none;
  }
}
.section-index-listmenu
  .listmenu-content
  .orders-product
  .orders-block:last-child {
  margin-bottom: 0px;
}
.section-index-listmenu .listmenu-content .orders-product .orders-block__title {
  position: relative;
  text-align: center;
  padding: 15px 15px 20px;
  border-bottom: 1px solid var(--shop-color-border);
}
@media (max-width: 991.9px) {
  .section-index-listmenu
    .listmenu-content
    .orders-product
    .orders-block__title {
    text-align: left;
    padding: 15px;
  }
}
.section-index-listmenu
  .listmenu-content
  .orders-product
  .orders-block__title
  h3 {
  position: relative;
  color: #333333;
  font-weight: 500;
  font-size: 22px;
}
.section-index-listmenu .listmenu-content .orders-product .orders-block__list {
  padding: 0;
}
@media (max-width: 767px) {
  .section-index-listmenu .listmenu-content .orders-product {
    width: 100%;
  }
  .section-index-listmenu
    .listmenu-content
    .orders-product
    .orders-block
    ul
    li
    a
    .info
    h3 {
    font-size: 14px;
  }
  .section-index-listmenu
    .listmenu-content
    .orders-product
    .orders-block
    ul
    li
    a
    .info
    .desc {
    font-size: 13px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}
.section-index-listmenu .listmenu-content .orders-cart {
  overflow: hidden;
  background: #fff;
  max-height: calc(100vh - 85px);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 1px solid #ddd;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart__content {
  position: relative;
  margin-bottom: 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart__content
  .orders-cart--scroll {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart__content
  .orders-cart--scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart__content
  .orders-cart--scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #b3b3b3;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart__content
  .orders-cart--scroll::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #b3b3b3;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart-time {
  z-index: 9;
  padding: 0 15px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--action,
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise {
  padding-top: 7px;
  padding-bottom: 7px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--action {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--action
  .textbox {
  margin-top: 7px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--action
  .textday {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin: 5px 0 0;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--action
  .textday
  i {
  margin-right: 4px;
  color: #096743;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise {
  padding-left: 20px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option {
  padding-left: 20px;
  margin: 4px 0;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 5px;
  height: 14px;
  width: 14px;
  color: #fff;
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  display: inline-block;
  transition: all 0.15s ease-out 0s;
  -webkit-transition: all 0.15s ease-out 0s;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]
  + .form-check-label {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  opacity: 0.8;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 14px;
  width: 14px;
  background: #ffffff;
  border: 1px solid #818181;
  box-sizing: border-box;
  border-radius: 50%;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0.2s;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 9px;
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  left: 2px;
  color: #ffffff;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transform-origin: center center;
  opacity: 0;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]:checked:before {
  background: #096743;
  border-color: #096743;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]:checked::after {
  transform: scale(1);
  opacity: 1;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.57, 1.9, 0, 1.52);
  transition-delay: 0.2s;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--choise
  .choise-option
  .form-check-input[type="radio"]:checked
  + .form-check-label {
  color: #000;
  opacity: 1;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select {
  display: none;
  padding: 10px 0;
  border-top: 1px solid #db1a2a;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  label {
  font-size: 13px;
  font-weight: 400;
  display: block;
  margin: 0 0 4px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  select {
  background: #ffffff;
  cursor: pointer;
  padding: 0 8px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  border: 1px solid #e5e5e5;
  height: 32px;
  outline: none !important;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  select
  [disabled],
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  select:invalid {
  color: #ededed;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  .btn-choise-submit {
  background: #db1a2a;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0 30px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box
  .btn-choise-submit.disabled {
  opacity: 0.75;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select
  .sub-time-box:last-of-type {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  #header-pick-time
  .cart-time--select.active {
  display: block;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart-item {
  padding: 20px 15px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item {
  position: relative;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dotted var(--shop-color-border);
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title {
  padding-right: 35px;
  margin-bottom: 8px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  -ms-flex-order: 1;
  order: 1;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  h4 {
  margin: 0;
  font-size: 14px;
  line-height: 1.35;
  color: #333333;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
}
@media (max-width: 991px) {
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-item
    .cart-item
    .cart-item-title
    h4 {
    font-size: 14px;
  }
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  h4
  a {
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  color: #333333;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  h4
  a:hover {
  color: #dd0c1b;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  p {
  margin: 0;
  font-size: 13px;
  color: #808080;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  p.variant_note {
  color: #096743;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-title
  p.variant_error {
  color: red;
  font-size: 12px;
  display: none;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-quantity {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  -ms-flex-order: 3;
  order: 3;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-quantity
  .qty-btn {
  background: rgba(9, 103, 67, 0.06);
  border: 1px solid rgba(9, 103, 67, 0.07);
  color: #096743;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-quantity
  .qty-btn
  svg {
  width: 10px;
  fill: #096743;
  stroke: #096743;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-quantity
  .qty-value {
  font-weight: 500;
  font-size: 15px;
  border: 0;
  width: 35px;
  text-align: center;
  line-height: 24px;
  height: 24px;
  padding: 0 5px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-price {
  text-align: right;
  -ms-flex-order: 2;
  order: 2;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-price
  p {
  font-weight: 600;
  font-size: 15px;
  margin: 0px 0 8px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-remove {
  font-size: 14px;
  color: #096743;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-item
  .cart-item-remove
  i {
  font-size: 12px;
  margin-right: 6px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-item
  .cart-empty {
  margin: 0;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart-bottom {
  position: relative;
  padding: 15px 15px 12px;
  background: #f0f0f0;
  border-top: 1px solid var(--shop-color-border);
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom:before {
  content: "";
  display: inline-block;
  height: 10px;
  margin: 0 auto;
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  text-align: center;
  width: 10px;
  box-shadow: 0 0 0 6px #ffffff;
  background: #096743;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom
  .flex-total {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: flex;
  -ms-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom
  .flex-total
  + .flex-total {
  margin-top: 5px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom
  .flex-total
  .orders-total {
  font-size: 20px;
  line-height: 1.2;
  padding-left: 15px;
  font-weight: 600;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom
  .flex-total
  .orders-total.small {
  font-size: 14px;
  font-weight: 600;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-bottom
  .flex-total
  .orders-total:not(.small) {
  color: #db1a2a;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart-payment {
  padding: 10px 15px;
  background: #f0f0f0;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-payment
  .btn-link {
  display: block;
  width: 100%;
  padding: 8px 10px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  background: red;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-payment
  .btn-link.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-payment
  .btn-link:hover {
  text-decoration: none;
  background: #3f393a;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-payment
  .btn-link.over-time {
  opacity: 0.7;
  pointer-events: none;
  font-size: 14px;
}
.section-index-listmenu .listmenu-content .orders-cart .orders-cart-coupon {
  padding: 7px 15px;
  background: #ffffff;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner {
  position: relative;
  padding: 6px 8px;
  background: var(--coupon-bkg);
  font-size: 13px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner:after,
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner:before {
  content: "";
  width: 8px;
  height: -webkit-calc(100% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  background-image: radial-gradient(var(--coupon-bkg) 3px, transparent 0);
  background-size: 8px 8px;
  background-position-x: -8px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner:before {
  left: -3px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner:after {
  right: -3px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  p {
  margin: 0;
  color: var(--coupon-text);
  font-size: 12px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  .cpi-bold {
  font-size: 14px;
  font-weight: 500;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  .coupon-left {
  padding-right: 15px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  .cpi-button {
  background: #fff;
  white-space: nowrap;
  border-radius: 4px;
  outline: none;
  padding: 2px 8px;
  margin-bottom: 2px;
  border: 1px solid #096743;
  background: #ffffff;
  color: #096743;
  font-size: 12px;
  font-weight: 500;
  min-width: 92px;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  .cpi-button:active {
  box-shadow: 0 0 0 3px rgba(9, 103, 67, 0.2);
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-inner
  .cpi-button.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-coupon
  .coupon-note {
  margin: 5px 0 0;
  font-size: 12px;
  display: none;
}
.section-index-listmenu
  .listmenu-content
  .orders-cart
  .orders-cart-time
  + .orders-cart-coupon {
  padding-top: 0;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .section-index-listmenu .listmenu-content .orders-cart {
    max-height: calc(100vh - 130px);
  }
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-time
    #header-pick-time
    .cart-time--action,
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-time
    #header-pick-time
    .cart-time--choise {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-time
    #header-pick-time
    .cart-time--choise {
    border-top: 1px solid #efefef;
    padding-left: 0;
  }
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-time
    #header-pick-time
    .cart-time--choise
    .choise-option {
    display: inline-block;
    vertical-align: middle;
  }
  .section-index-listmenu
    .listmenu-content
    .orders-cart
    .orders-cart-time
    #header-pick-time
    .cart-time--choise
    .choise-option:not(:last-of-type) {
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .section-index-listmenu .listmenu-content .orders-cart {
    border-radius: 0;
    -webkit-border-radius: 0;
  }
}
.section-index-listmenu .listmenu-content .sticky-right {
  position: sticky;
  top: 80px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}
@media (max-width: 1199px) {
  .section-index-listmenu .listmenu-content .sticky-right {
    top: 125px;
  }
}
@media (max-width: 991px) {
  .section-index-listmenu .listmenu-content .listmenu-site-cart {
    position: fixed;
    bottom: 0;
    z-index: 996;
    background: transparent;
    visibility: hidden;
  }
  .section-index-listmenu .listmenu-content .listmenu-site-cart .sticky-right {
    visibility: visible;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    top: initial;
    padding: 0;
    transform: translateY(calc(100% - 48px));
    transition: transform 0.5s ease, bottom 0.3s ease;
    z-index: 99;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
    display: flex;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .orders-cart {
    display: none;
    visibility: hidden;
    max-height: 100%;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.addthis_mobile {
    padding-bottom: 0;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.active,
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.active.addthis_mobile {
    transform: translateY(0);
    max-height: 90%;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.active
    .orders-cart {
    display: block;
    visibility: visible;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.active
    .cart-mobile {
    border-radius: 16px 16px 0 0;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.active
    .cart-mobile:before {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: -14px;
    width: 48px;
    height: 6px;
    border-radius: 3px;
    background: #ffffff;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.modal-quickview-show {
    transform: translateY(100%);
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile {
    background: #096743;
    color: #fff;
    padding: 0 5px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box {
    height: 50px;
    padding-bottom: 6px;
    align-items: center;
    justify-content: space-between;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box
    span {
    width: calc(100% / 3);
    padding: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box
    span:nth-child(2) {
    font-weight: 600;
    text-align: center;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box
    span:last-child {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box {
    font-size: 12px;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right
    .cart-mobile
    > .flex-box
    span:nth-child(2) {
    font-size: 14px;
  }
  .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right.addthis_mobile {
    padding-bottom: 44px;
    transform: translateY(calc(100% - 90px));
  }
}
@media (min-width: 1200px) {
  .section-index-listmenu .listmenu-site-nav {
    -ms-flex: 0 0 23%;
    flex: 0 0 23%;
    max-width: 23%;
  }
  .section-index-listmenu .listmenu-site-prod:not(.listmenu-auto) {
    -ms-flex: 0 0 46%;
    flex: 0 0 46%;
    max-width: 46%;
  }
  .section-index-listmenu .listmenu-site-cart:not(.listmenu-auto) {
    -ms-flex: 0 0 31%;
    flex: 0 0 31%;
    max-width: 31%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .section-index-listmenu > .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-index-listmenu .listmenu-site-nav {
    -ms-flex: 0 0 24%;
    flex: 0 0 24%;
    max-width: 24%;
    padding-right: 5px;
  }
  .section-index-listmenu .listmenu-site-prod:not(.listmenu-auto) {
    -ms-flex: 0 0 44%;
    flex: 0 0 44%;
    max-width: 44%;
  }
  .section-index-listmenu .listmenu-site-cart:not(.listmenu-auto) {
    -ms-flex: 0 0 32%;
    flex: 0 0 32%;
    max-width: 32%;
    padding-left: 5px;
  }
}
@media (max-width: 991px) {
  .section-index-listmenu {
    padding: 0 0 0;
    background: var(--shop-color-bgmobile);
  }
  .section-index-listmenu .listmenu-heading {
    margin: 0;
    padding: 30px 10px;
  }
  .section-index-listmenu .listmenu-heading h2 {
    font-size: 24px;
  }
  .section-index-listmenu .container-fluid,
  .section-index-listmenu .col-12 {
    padding: 0;
  }
  .section-index-listmenu .row {
    margin: 0;
  }
}

.overlay-order {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 998;
  background: rgba(0, 0, 0, 0.5);
}

body.cart-open {
  overflow: hidden;
}

body.cart-open .overlay-order {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  body.cart-open .section-index-listmenu .listmenu-content .listmenu-site-cart {
    top: 0;
    z-index: 1002;
  }
  body.cart-open
    .section-index-listmenu
    .listmenu-content
    .listmenu-site-cart
    .sticky-right {
    visibility: visible;
  }
}

/* End Content Page Order */
/* Swatch Color */
.color.swatch-element label.do span {
  background: #ff0000 !important;
}

.color.swatch-element label.vang span {
  background: #ffff05 !important;
}

.color.swatch-element label.cam span {
  background: #f5b505 !important;
}

.color.swatch-element label.xanh-duong span {
  background: #5100ff !important;
}

.color.swatch-element label.xanh-la span {
  background: #3cfa08 !important;
}

.color.swatch-element label.nau span {
  background: #753a3a !important;
}

.color.swatch-element label.xam span {
  background: #cccaca !important;
}

.color.swatch-element label.tim span {
  background: #b5129a !important;
}

.color.swatch-element label.hong span {
  background: #db999b !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.nau span {
  background: #753a3a !important;
}

.color.swatch-element label.xam span {
  background: #cccaca !important;
}

.color.swatch-element label.tim span {
  background: #b5129a !important;
}

.color.swatch-element label.hong span {
  background: #db999b !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

.color.swatch-element label.do span {
  background: #ff0000 !important;
}

.color.swatch-element label.vang span {
  background: #ffff05 !important;
}

.color.swatch-element label.cam span {
  background: #f5b505 !important;
}

.color.swatch-element label.xanh-duong span {
  background: #5100ff !important;
}

.color.swatch-element label.xanh-la span {
  background: #3cfa08 !important;
}

.color.swatch-element label.nau span {
  background: #753a3a !important;
}

.color.swatch-element label.xam span {
  background: #cccaca !important;
}

.color.swatch-element label.tim span {
  background: #b5129a !important;
}

.color.swatch-element label.hong span {
  background: #db999b !important;
}

.color.swatch-element label.den span {
  background: #000000 !important;
}

/* ____________ */
/* Quickview */
#quick-view-modal {
  text-align: center;
}
#quick-view-modal .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  font-size: 14px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
#quick-view-modal .modal-dialog .modal-content {
  border-radius: 0;
  border: 0;
}
#quick-view-modal .modal-dialog .modal-content .flex-box {
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: flex;
}
@media (max-width: 767px) {
  #quick-view-modal .modal-dialog .modal-content .flex-box {
    -ms-flex-pack: center;
    justify-content: center;
  }
}
#quick-view-modal .modal-dialog .modal-content .product-title {
  padding: 15px 30px;
  border-bottom: 1px solid #e5e5e5;
}
@media (max-width: 767px) {
  #quick-view-modal .modal-dialog .modal-content .product-title {
    padding: 30px 30px 15px 30px;
  }
}
@media (max-width: 360px) {
  #quick-view-modal .modal-dialog .modal-content .product-title {
    padding: 15px;
  }
}
#quick-view-modal .modal-dialog .modal-content .product-title img {
  width: 80px;
  margin-right: 10px;
  cursor: pointer;
}
@media (max-width: 767px) {
  #quick-view-modal .modal-dialog .modal-content .product-title img {
    max-height: 80px;
  }
}
#quick-view-modal .modal-dialog .modal-content .product-title .info {
  width: calc(100% - 90px);
}
#quick-view-modal .modal-dialog .modal-content .product-title .info h4 {
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  font-weight: 500;
}
#quick-view-modal .modal-dialog .modal-content .close-quickview {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
#quick-view-modal .modal-dialog .modal-content .close-quickview svg {
  stroke: #999;
  width: 100%;
  height: 100%;
}
#quick-view-modal .modal-dialog .modal-content .close-quickview:hover svg {
  stroke: #333;
}
#quick-view-modal .modal-dialog .modal-content .quickview-des {
  font-size: 16px;
  line-height: 1.4;
  color: #191919;
  padding: 15px 30px 0;
  font-weight: 300;
}
#quick-view-modal .modal-dialog .modal-content .quickview-des p {
  margin: 0;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__inner {
  position: relative;
  overflow: hidden;
  max-height: 160px;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__inner:after {
  content: "";
  transition-property: top;
  transition-duration: 300ms;
  display: none;
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #fff 100%);
  position: absolute;
  top: calc(100% - 6.25em);
  left: 0;
  height: 6.25em;
  width: 100%;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__inner.opened {
  height: 160px;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__inner.opened:after {
  display: block;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__trigger {
  visibility: hidden;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__trigger
  .btn-toggle {
  display: inline-block;
  outline: none;
  padding: 9px 0px;
  font-size: 14px;
  color: #000000;
  background: #fff;
  border-radius: 0px;
  font-weight: 400;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .quickview-des
  .quickview-des__inner.opened
  + .quickview-des__trigger {
  visibility: visible;
}
#quick-view-modal .modal-dialog .modal-content .product-note {
  padding: 30px 30px 30px;
  border-bottom: 1px solid #e5e5e5;
}
@media (max-width: 360px) {
  #quick-view-modal .modal-dialog .modal-content .product-note {
    padding: 15px;
  }
}
#quick-view-modal .modal-dialog .modal-content .product-note .note-box {
  position: relative;
}
#quick-view-modal .modal-dialog .modal-content .product-note .note-box svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
#quick-view-modal .modal-dialog .modal-content .product-note .note-box input {
  width: 100%;
  border: 0 none;
  padding: 0 10px 0 40px;
  line-height: 38px;
  background: #fff;
  color: #333;
  border: 1px solid #7f7f7f;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
#quick-view-modal .modal-dialog .modal-content .product-actions {
  padding: 12px 30px;
}
#quick-view-modal .modal-dialog .modal-content .product-actions .error-max {
  display: none;
  font-size: 13px;
  color: red;
  margin: -4px 0 4px;
}
#quick-view-modal .modal-dialog .modal-content .product-actions .actions-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .select-quantity {
  float: left;
}
@media (max-width: 767px) {
  #quick-view-modal
    .modal-dialog
    .modal-content
    .product-actions
    .actions-btn
    .select-quantity {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .select-quantity
  .qty-btn {
  background: transparent;
  border: 1px solid;
  color: #db1a2a;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .select-quantity
  .qty-btn
  svg {
  fill: #096743;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .select-quantity
  .quantity-selector {
  border: 0;
  width: 40px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 5px;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .btn-cart {
  float: right;
  padding: 0 30px;
  background: #096743;
  color: #fff;
  font-size: 0.875rem;
  line-height: 38px;
  border: 1px solid #096743;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .btn-cart
  span.product-price {
  font-weight: 400;
  float: right;
  padding: 0 0 0 50px;
  border: 0;
  margin: 0;
}
@media (max-width: 767px) {
  #quick-view-modal
    .modal-dialog
    .modal-content
    .product-actions
    .actions-btn
    .btn-cart {
    width: 100%;
    padding: 0 10px;
  }
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .btn-cart.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: none;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .product-actions
  .actions-btn
  .btn-contact-hotline {
  float: right;
}
#quick-view-modal .modal-dialog .modal-content .select-swatch {
  padding: 30px 30px 0 30px;
}
#quick-view-modal
  .modal-dialog
  .modal-content
  .select-swatch
  .swatch:last-child {
  border: none;
  margin: 0;
  padding: 0;
}
#quick-view-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

/* End Quickview */
/* Swatch */
.selector-wrapper {
  display: none;
}

.swatch {
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  width: 100%;
  float: left;
  border-bottom: 1px dotted #dfe0e1;
}
.swatch .header {
  margin: 0 0 8px;
  font-size: 14px;
  text-align: left;
  line-height: initial;
  font-weight: 400;
  color: #333;
}
.swatch input {
  display: none;
}
.swatch > label.tt-label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  text-transform: uppercase;
  width: 85px;
  text-align: right;
}
.swatch .select-swap {
  display: inline-block;
  vertical-align: middle;
}
.swatch .swatch-element {
  display: inline-block;
  margin: 5px 10px 5px 0;
  position: relative;
  vertical-align: bottom;
}
.swatch .swatch-element.color {
  margin: 0 10px 0 0;
  line-height: 0;
}
.swatch .swatch-element.color label {
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.swatch .swatch-element.color label.sd {
  background: transparent;
  border: 1px solid #808284;
}
.swatch .swatch-element.color span {
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
  font-size: 0;
  border-radius: 50%;
  border: 1px solid #dfe0e1;
}
.swatch .swatch-element.color.soldout label span {
  overflow: hidden;
  position: relative;
}
.swatch .swatch-element.color.soldout label span:before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  background: url(//theme.hstatic.net/200000299178/1000739054/14/sold_out.png?v=76)
    no-repeat;
  background-size: contain;
}
@media (max-width: 767px) {
  .swatch .swatch-element.color {
    margin: 5px 8px 5px 0;
  }
}
.swatch .swatch-element.soldout:not(.color) label {
  overflow: hidden;
}
.swatch .swatch-element.soldout:not(.color) label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #e5e5e5;
  background: url(//theme.hstatic.net/200000299178/1000739054/14/sold_out.png?v=76)
    no-repeat;
  background-size: contain;
}
.swatch .swatch-element:not(.color) label {
  display: block;
  margin: 0;
  line-height: 22px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 25px;
}
.swatch .swatch-element:not(.color) label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #e5e5e5;
  background: #fff;
}
.swatch .swatch-element:not(.color) label.sd:before {
  background: #096743;
  border-color: #096743;
}
.swatch .swatch-element:not(.color) label.sd:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 7px;
  height: 13px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  border-radius: 0;
}
.swatch label[for="product-select-option-0"] {
  display: none;
}
.swatch label[for="product-select-option-1"] {
  display: none;
}
.swatch label[for="product-select-option-2"] {
  display: none;
}
.swatch #product-select-option-0 {
  display: none;
}
.swatch #product-select-option-1 {
  display: none;
}
.swatch #product-select-option-2 {
  display: none;
}
.swatch #product-select-option-0 + .custom-style-select-box {
  display: none !important;
}
.swatch #product-select-option-1 + .custom-style-select-box {
  display: none !important;
}
.swatch #product-select-option-2 + .custom-style-select-box {
  display: none !important;
}

/* End Swatch */
/* Menu Mobile */
#overlay-menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 11;
  background: rgba(0, 0, 0, 0.5);
}

#menu-mobile-wrapper {
  position: fixed;
  top: 0 !important;
  left: 0;
  z-index: 99;
  width: 288px;
  transition: all 0.7s;
  background: white;
  height: 100%;
  transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  overflow: hidden;
}
#menu-mobile-wrapper .menu-mobile--inner .menu-mobile--logo a {
  background: #096743;
  color: white;
  padding: 10px 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  margin: 0 0 15px 0;
  text-transform: uppercase;
}
#menu-mobile-wrapper .menu-mobile--inner .menu-mobile--wrapper {
  padding: 0 20px;
}
#menu-mobile-wrapper .menu-mobile--inner .menu-mobile--wrapper > ul {
  margin: 0;
  padding: 0;
}
#menu-mobile-wrapper .menu-mobile--inner .menu-mobile--wrapper > ul > li > a {
  padding: 10px 5px;
  display: inline-block;
  text-transform: uppercase;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  > ul
  > li
  .sub-menu-mobile-1 {
  width: 100%;
  display: none;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  > ul
  > li
  .sub-menu-mobile-1
  > li
  > a {
  padding: 10px 5px;
  display: inline-block;
  text-transform: uppercase;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  > ul
  > li
  .sub-menu-mobile-1
  > li
  .sub-menu-mobile-2 {
  width: 100%;
  display: none;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  > ul
  > li
  .sub-menu-mobile-1
  > li
  .sub-menu-mobile-2
  > li
  > a {
  padding: 10px 5px;
  display: inline-block;
  text-transform: uppercase;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  .toggle-sub-menu {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
}
#menu-mobile-wrapper
  .menu-mobile--inner
  .menu-mobile--wrapper
  .toggle-sub-menu
  .fa-minus:before {
  content: "\f068" !important;
}

body.show-menu-mobile {
  overflow: hidden;
}
body.show-menu-mobile #menu-mobile-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
body.show-menu-mobile #menu-mobile-wrapper ~ #overlay-menu-mobile {
  visibility: visible;
  opacity: 1;
}

/* ___________ */
.product-loop {
  position: relative;
  background-color: #fff;
  height: 100%;
  transition: all ease-out 0.3s;
  border: 1px solid #fbfbfb;
  overflow: hidden;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.product-loop .product-img {
  margin: 0 0 15px 0;
  position: relative;
}
.product-loop .product-img .proloop-sale {
  position: absolute;
  z-index: 8;
  top: 8px;
  left: 8px;
  padding: 0;
  background: #ffffff;
  color: #f94c43;
  font-weight: 700;
  padding: 0px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #f3f5f9;
  border: 1px solid #f3f5f9;
}
.product-loop .product-img .sold-out {
  position: absolute;
  z-index: 8;
  background: #575757;
  color: white;
  padding: 5px 15px;
  font-size: 16px;
  top: 0;
  right: 0;
}
@media (max-width: 991px) {
  .product-loop .product-img .sold-out {
    padding: 3px 8px;
    font-size: 12px;
  }
}
.product-loop .product-img .proloop-image {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.product-loop .product-img .proloop-image--inner {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.product-loop .product-img .proloop-image--hover {
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -ms-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -moz-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.product-loop .product-img .proloop-image .pro-image {
  opacity: 1;
  -ms-opacity: 1;
  -o-opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.product-loop .product-img .proloop-image .pro-image.first-image {
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-loop .product-img .proloop-image .pro-image.first-image picture {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.product-loop .product-img .proloop-image .pro-image.second-image {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 100%;
  opacity: 0;
}
.product-loop .product-img .proloop-image .pro-image.second-image picture {
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: contain;
  object-fit: contain;
}
.product-loop .product-img .proloop-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.product-loop .product-detail {
  padding: 0 15px 20px;
}
.product-loop .product-detail .proloop-name {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0px;
  font-family: inherit;
}
.product-loop .product-detail .proloop-name a {
  color: #333333;
  white-space: initial;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.product-loop .product-detail .proloop-name a:hover {
  color: #dd0c1b;
}
.product-loop .product-detail .proloop-price {
  margin: 6px 0 12px;
}
.product-loop .product-detail .proloop-price span {
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  opacity: 0.92;
  margin-right: 6px;
}
.product-loop .product-detail .proloop-price del {
  font-size: 13px;
  color: gray;
}
.product-loop .product-detail .proloop-action .btn-addcart {
  position: relative;
  z-index: 1;
  font-size: 13px;
  color: #333333;
  background-color: #fff;
  border: 0;
  background-image: linear-gradient(to right, #096743 0, #096743 100%);
  background-repeat: no-repeat;
  background-size: 0 100%;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 30px;
  padding: 0 25px 0 0;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.product-loop .product-detail .proloop-action .btn-addcart .ico-shopping {
  display: inline-block;
  font-size: 14px;
  width: 35px;
  height: 35px;
  line-height: 33px;
  border-radius: 35px;
  margin-right: 8px;
  color: #ffffff;
  background-color: #096743;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-loop .product-detail .proloop-action .btn-addcart:hover {
  color: #fff;
  background-size: 100% 100%;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-loop .product-detail .proloop-action .btn-addcart:hover .ico-shopping {
  text-indent: 8px;
}
@media (min-width: 1200px) {
  .product-loop:hover {
    box-shadow: 0 3px 13px rgba(0, 0, 0, 0.06);
  }
  .product-loop:hover .product-img .proloop-image--hover {
    -ms-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -ms-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -o-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -moz-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    -webkit-transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  .product-loop:hover .product-img .proloop-image .pro-image.second-image {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .product-loop .product-detail {
    padding: 0 10px 15px;
  }
  .product-loop .product-detail .proloop-action .btn-addcart {
    padding-right: 15px;
    font-size: 12px;
  }
}

.listProduct-row {
  margin-left: -9px;
  margin-right: -9px;
}
.listProduct-row > div {
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .listProduct-row .col-product-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.layoutPage-collection .wrapper-collection-content {
  margin-top: 40px;
}

.collection-content-category {
  margin-bottom: 40px;
}
.collection-content-category .listing-ourmenu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background: white;
  overflow: hidden;
}
.collection-content-category .listing-ourmenu .ourmenu-item {
  position: relative;
  padding: 15px 10px;
  text-align: center;
  transition: all 0.5s ease;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.collection-content-category .listing-ourmenu .ourmenu-item .box-thumb {
  margin: 0 0 15px 0;
}
.collection-content-category
  .listing-ourmenu
  .ourmenu-item
  .box-thumb
  .lazyload-thumb {
  position: relative;
  display: inline-block;
}
.collection-content-category
  .listing-ourmenu
  .ourmenu-item
  .box-thumb
  .lazyload-thumb__placeholder {
  position: absolute;
  width: 54%;
  height: 54%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border: none;
  overflow: hidden;
}
.collection-content-category
  .listing-ourmenu
  .ourmenu-item
  .box-thumb
  .lazyload-thumb
  svg {
  height: 75px;
  fill: #e9e8e9;
  stroke: transparent;
  transition: all 0.5s ease;
}
.collection-content-category .listing-ourmenu .ourmenu-item .box-info {
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.8px;
  font-size: 14px;
  color: #333333;
}
.collection-content-category .listing-ourmenu .ourmenu-item .box-link {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: none;
}
.collection-content-category
  .listing-ourmenu
  .ourmenu-item.active
  .box-thumb
  img,
.collection-content-category
  .listing-ourmenu
  .ourmenu-item:hover
  .box-thumb
  img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.collection-content-category
  .listing-ourmenu
  .ourmenu-item.active
  .box-thumb
  svg,
.collection-content-category
  .listing-ourmenu
  .ourmenu-item:hover
  .box-thumb
  svg {
  fill: #dd0c1b;
}
.collection-content-category .listing-ourmenu .ourmenu-item.active .box-info,
.collection-content-category .listing-ourmenu .ourmenu-item:hover .box-info {
  color: #dd0c1b;
}

.collection-sidebar-left .sidebar-group {
  margin-bottom: 30px;
}
.collection-sidebar-left .sidebar-group.sidebar-group-linklist {
  border: 1px solid #e3e5ec;
  padding: 20px;
}
.collection-sidebar-left .sidebar-group.sidebar-group-linklist .menuList-links {
  margin: 0;
  padding: 0;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li {
  position: relative;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li
  a {
  color: #333333;
  position: relative;
  font-size: 14px;
  display: block;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li
  a:hover {
  color: #dd0c1b;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li
  ul.submenu-links {
  display: none;
  padding: 8px 0;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li {
  border-bottom: 1px dashed #e7e7e7;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li:last-child {
  border-bottom: none;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li
  > a {
  padding: 12px 0;
  font-weight: 500;
  font-size: 15px;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li.has-submenu
  > a {
  padding-right: 30px;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li.has-submenu
  span.icon-plus-submenu {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid transparent;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  .icon-plus-submenu:after,
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  .icon-plus-submenu:before {
  background: rgba(9, 9, 9, 0.6);
  content: "";
  display: block;
  position: absolute;
  margin-right: 7px;
  top: 50%;
  right: 0;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  .icon-plus-submenu:before {
  width: 9px;
  height: 1px;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  .icon-plus-submenu:after {
  width: 1px;
  height: 9px;
  right: 4px;
  transform-origin: center;
  -moz-transform: translateY(-50%) scale(1, 1);
  -ms-transform: translateY(-50%) scale(1, 1);
  -webkit-transform: translateY(-50%) scale(1, 1);
  transform: translateY(-50%) scale(1, 1);
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li.has-submenu.opened
  > a
  .icon-plus-submenu:after {
  -moz-transform: translateY(-50%) scale(1, 0);
  -ms-transform: translateY(-50%) scale(1, 0);
  -webkit-transform: translateY(-50%) scale(1, 0);
  transform: translateY(-50%) scale(1, 0);
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  > li.has-submenu
  .icon-plus-submenu:hover {
  border: 1px solid #e7e7e7;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  ul.submenu-links
  li
  a {
  padding: 8px 0 8px 0px;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li.has-submenu.level0
  > ul.submenu-links {
  border-top: 1px dashed #e7e7e7;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li.has-submenu.level0
  > ul.submenu-links
  li
  a {
  padding-left: 12px;
}
.collection-sidebar-left
  .sidebar-group.sidebar-group-linklist
  .menuList-links
  li.has-submenu.level1
  > ul.submenu-links
  li
  a {
  padding-left: 25px;
}

@media (min-width: 768px) {
  .collection-sidebar-left .collection-sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 25px;
    -webkit-transition: top 400ms ease;
    -moz-transition: top 400ms ease;
    transition: top 400ms ease;
  }
  body.scroll-body.scroll-body-up
    .collection-sidebar-left
    .collection-sidebar-sticky {
    top: 90px;
  }
}

/*--------------------------------------------------------------------
  PRODUCT DETAIL 
  --------------------------------------------------------------------*/
.product-detail-wrapper {
  padding: 20px 0 60px 0;
}

.product-detail-main {
  margin-bottom: 40px;
}

.product-title {
  padding: 0 0 10px;
  border-bottom: 1px dotted #dfe0e1;
}

.product-title h1 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 5px;
  line-height: initial;
}

#pro_sku {
  font-size: 12px;
  color: #a3a5a7;
}

.hook-reviews {
  border-bottom: 1px dotted #dfe0e1;
  padding-bottom: 15px;
}

.hook-reviews .rating {
  line-height: 1;
  position: relative;
  white-space: nowrap;
  display: inline-block;
}

.hook-reviews .rating .rating {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  line-height: 1;
}

.hook-reviews .rating .icon {
  display: inline-block;
}

.hook-reviews .rating .icon::before {
  color: #d5d5d5;
  content: "\f005";
  font-family: "Font Awesome 5 Pro", sans-serif;
  font-size: 13px;
  font-style: normal;
}

.hook-reviews .rating .icon.star-full::before {
  color: #ffae00;
}

.number-rating {
  padding-left: 5px;
  font-size: 13px;
}

#add-item-form .selector-actions .btn-contact-hotline {
  background: orange;
  color: white;
  width: 100%;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: none;
  padding: 14px 35px;
  line-height: 22px;
  font-size: 16px;
  border-radius: 0;
}
#add-item-form .selector-actions .btn-contact-hotline:hover,
#add-item-form .selector-actions .btn-contact-hotline:focus {
  background: #096743;
  color: white;
}

#add-item-form.disabled .selector-actions,
#add-item-form.disabled .product-action-bottom {
  pointer-events: none;
  opacity: 0.8;
}

#add-item-form .error_max {
  font-weight: 500;
  font-size: 14px;
  color: red;
  margin: 12px 0;
}

#add-item-form .error_bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(100% - 3px);
  border-top: 1px solid #ececec;
  text-align: center;
  font-size: 12px;
  color: red;
  background: #ffffff;
  margin: 0;
  padding: 4px 15px 0;
}

.product-price {
  padding: 10px 0;
  border-bottom: 1px dotted #dfe0e1;
  margin: 0 0 20px 0;
}
.product-price .contact-price-detail {
  font-size: 18px;
  opacity: 0.92;
  font-weight: 500;
  color: #db1a2a;
}

.product-price span.pro-price {
  font-size: 18px;
  opacity: 0.92;
  font-weight: 500;
  color: #db1a2a;
}

.product-price span.pro-sale {
  display: inline-block;
  padding: 5px 15px;
  margin-right: 10px;
  background: #ededed;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #f94c43;
}

.product-price del {
  font-size: 14px;
  color: #777a7b;
  padding-left: 10px;
  font-weight: 500;
}

.selector-actions {
  width: 100%;
}

.quantity-area {
  margin-bottom: 15px;
}

.quantity-area input#quantity {
  background: #fff;
  font-weight: 500;
  height: 32px;
  padding: 0;
  text-align: center;
  width: 70px;
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
  border-radius: 1px;
  float: left;
  -webkit-appearance: none;
}

.quantity-area input.qty-btn {
  float: left;
  background: #f5f5f5;
  border: solid 1px #f5f5f5;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  outline: none;
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 0;
}

.note-detail-product {
  width: 100%;
  margin: 20px 0;
}
.note-detail-product label {
  font-weight: bold;
  margin: 0 0 5px 0;
}
.note-detail-product input {
  width: 100%;
  color: #333;
  border: 1px solid #7f7f7f;
}

button.btn-addtocart {
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: none;
  padding: 14px 35px;
  line-height: 22px;
  width: 100%;
  border-radius: 0;
  font-size: 16px;
}

button.btn-addtocart[disabled] {
  opacity: 0.8;
}

.product-description {
  margin: 20px 0;
}

.product-description .title-bl h2,
.product-description h2,
.product-description h3 {
  font-size: 14px;
  font-weight: 500;
}

.description-productdetail * {
  max-width: 100% !important;
}

.description-productdetail ul {
  list-style: initial;
  padding-left: 20px;
}

.description-productdetail ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.description-productdetail ul ul {
  list-style-type: circle;
}

.description-productdetail ul ul ul {
  list-style-type: square;
}

.description-productdetail ul li,
.description-productdetail ol li {
  margin: 5px 0;
}

.description-productdetail {
  overflow: hidden;
  position: relative;
}
.description-productdetail:after {
  content: "";
  transition-property: top;
  transition-duration: 300ms;
  display: none;
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #fff 100%);
  position: absolute;
  top: calc(100% - 6.25em);
  left: 0;
  height: 6.25em;
  width: 100%;
}
.description-productdetail.less {
  max-height: 250px;
}
.description-productdetail.less:after {
  display: block;
}

.more-less-description span {
  cursor: pointer;
}

.product-gallery__thumbs-container {
  width: 9%;
  margin-right: 10px;
}

.product-gallery__thumbs {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 1px;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.product-gallery__thumb a {
  background-color: #f3f3f3;
  display: block;
}

.product-gallery__thumb {
  line-height: 0;
  margin-bottom: 10px;
  border: 1px solid #f7f7f8;
  width: 100%;
}

.product-gallery__thumb:last-child {
  margin-bottom: 0;
}

.product-gallery__thumb:hover {
  border-color: #a3a5a7;
}

.product-gallery__thumb img {
  cursor: pointer;
  background-color: #f7f7f8;
  width: 100%;
}

.product-gallery__thumb.active,
.product-gallery__thumb.active:hover {
  border-color: #808284;
}

.product-gallery__thumb.active img {
  opacity: 0.9;
  width: 100%;
}

.product-gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-image-detail {
  width: 91%;
  padding-left: 10px;
  position: relative;
}

.product-gallery-item {
  text-align: center;
  margin-bottom: 10px;
}

.product-full-img {
  text-align: center;
}

.product-full-img .no_image {
  width: 50%;
}

/* ________ */
@media (min-width: 992px) {
  .product-image__button {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 10;
  }
}

.product-zoom,
.gallery-index {
  position: absolute;
  z-index: 6;
}

.product-zoom {
  width: 30px;
  height: 30px;
  overflow: visible;
  cursor: pointer;
  top: auto;
  bottom: 48px;
  right: 0;
  left: 0;
  margin: auto;
  -ms-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    right 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    right 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    right 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.product-zoom polyline,
.product-sharing .icon svg {
  fill: #000;
}

.product-zoom polyline {
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  transform-origin: center;
  -ms-transition: transform 150ms linear;
  -webkit-transition: transform 150ms linear;
  transition: transform 150ms linear;
}

@media (min-width: 992px) {
  .product-zoom:hover polyline:nth-child(1) {
    -ms-transform: scale(0.9) translate3d(-2px, -2px, 0);
    -webkit-transform: scale(0.9) translate3d(-2px, -2px, 0);
    transform: scale(0.9) translate3d(-2px, -2px, 0);
  }
  .product-zoom:hover polyline:nth-child(2) {
    -ms-transform: scale(0.9) translate3d(2px, -2px, 0);
    -webkit-transform: scale(0.9) translate3d(2px, -2px, 0);
    transform: scale(0.9) translate3d(2px, -2px, 0);
  }
  .product-zoom:hover polyline:nth-child(3) {
    -ms-transform: scale(0.9) translate3d(2px, 2px, 0);
    -webkit-transform: scale(0.9) translate3d(2px, 2px, 0);
    transform: scale(0.9) translate3d(2px, 2px, 0);
  }
  .product-zoom:hover polyline:nth-child(4) {
    -ms-transform: scale(0.9) translate3d(-2px, 2px, 0);
    -webkit-transform: scale(0.9) translate3d(-2px, 2px, 0);
    transform: scale(0.9) translate3d(-2px, 2px, 0);
  }
  /**** PRODUCT DETAIL ****/
  .icon-pr-fix.out-with-you {
    opacity: 0;
  }
  .product-content-desc {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
  }
}

.gallery-index {
  display: none;
  bottom: 55px;
  width: 47px;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  cursor: default;
  right: 10%;
  -ms-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.icon-pr-fix {
  -ms-transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

/* ------------------- Zoom product  ------------------------ */
#divzoom {
  position: fixed;
  display: none;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  background: #fff;
}

#divzoom #closedivZoom {
  position: absolute;
  z-index: 1000;
  border: 2px solid #333;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
}

#divzoom #closedivZoom i:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background: #333;
  left: 20%;
  top: calc(50% - 3px);
  z-index: 1001;
  display: block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#divzoom #closedivZoom i:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 2px;
  background: #333;
  right: 30%;
  top: calc(50% - 3px);
  z-index: 1001;
  display: block;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#divzoom #closedivZoom i {
  position: relative;
  width: 40px;
  height: 40px;
  display: block;
}

.divzoom_main {
  height: calc(100vh - 40px);
}

.divzoom_main .product-thumb {
  height: 100%;
  width: 100%;
}

.divzoom_main .product-thumb img {
  max-height: 100%;
}

body.open_layer {
  overflow: hidden;
}

.list-productRelated h2 {
  text-transform: uppercase;
  margin: 10px 0 50px;
  font-size: 24px;
  font-weight: bold;
}

.list-productRelated .pro-loop .product-img button {
  width: 70%;
}

@media (min-width: 1024px) {
  .list-productRelated .pro-loop {
    width: 20%;
  }
}

.description-productdetail table {
  min-width: initial !important;
}

/* STYLE PRODUCT PAGE */
.pr_style_02 .product-image-detail {
  width: 100%;
  padding-left: 0px;
  position: relative;
}

.pr_style_02 .product-gallery-item {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .pr_style_02 .product-gallery-item {
    width: 50%;
    padding-right: 5px;
    float: left;
  }
  .pr_style_02 .product-gallery-item:nth-of-type(2n) {
    padding-left: 5px;
    padding-right: 0;
  }
  .pr_style_03 button.btn-addtocart {
    width: 60%;
  }
  .pr_style_03 .note-detail-product input {
    width: 60%;
  }
  .pr_style_03 .note-detail-product label {
    width: 100%;
  }
  .pr_style_02 .product-gallery-item:nth-of-type(2n + 1) {
    clear: both;
  }
  .pr_style_03 .product-gallery__thumbs {
    position: initial;
    top: 0;
  }
  .pr_style_03 .product-content-img .product-gallery {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
  }
  .pr_style_03 .product-content-desc {
    position: initial;
    top: 0;
  }
  .pr_style_03 .product-gallery__thumbs-container {
    width: 12%;
  }
  .pr_style_03 .product-image-detail {
    width: 88%;
  }
}

/*======  SIDEBAR ==================================================*/
.list-blogs-latest .item-article {
  border-bottom: 1px #efefef dotted;
  padding: 15px 0;
  margin: 0;
}

.list-blogs-latest .item-article:last-child {
  border-bottom: none;
}

.list-blogs-latest .item-article .post-image {
  width: 30%;
  float: left;
  position: relative;
}

.list-blogs-latest .item-article .post-content {
  width: 70%;
  float: left;
  padding-left: 10px;
}

.list-blogs-latest .item-article .post-content h3 {
  font-family: inherit;
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 500;
}

.list-blogs-latest .item-article .post-content .post-meta {
  font-size: 13px;
  margin: 0;
}

.menuList-links {
  margin: 0;
}

.menuList-links li {
  position: relative;
}

.menuList-links li a {
  color: #333333;
  position: relative;
  font-size: 13px;
  display: block;
}

.menuList-links li a:hover,
.menuList-links li a:focus {
  color: #dd0c1b;
}

.menuList-links > li {
  border-bottom: 1px dashed var(--shop-color-border);
}

.menuList-links > li:last-child {
  border-bottom: none;
}

.menuList-links > li > a {
  padding: 12px 0;
  font-weight: 500;
  font-size: 15px;
}

.menuList-links > li.has-submenu > a {
  padding-right: 30px;
}

.menuList-links > li.has-submenu span.icon-plus-submenu {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid transparent;
}

.icon-plus-submenu:after,
.icon-plus-submenu:before {
  background: rgba(9, 9, 9, 0.6);
  content: "";
  display: block;
  position: absolute;
  margin-right: 7px;
  top: 50%;
  right: 0;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.icon-plus-submenu:before {
  width: 9px;
  height: 1px;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-plus-submenu:after {
  width: 1px;
  height: 9px;
  right: 4px;
  transform-origin: center;
  -moz-transform: translateY(-50%) scale(1, 1);
  -ms-transform: translateY(-50%) scale(1, 1);
  -webkit-transform: translateY(-50%) scale(1, 1);
  transform: translateY(-50%) scale(1, 1);
}

li.has-submenu.opened > a .icon-plus-submenu:after {
  -moz-transform: translateY(-50%) scale(1, 0);
  -ms-transform: translateY(-50%) scale(1, 0);
  -webkit-transform: translateY(-50%) scale(1, 0);
  transform: translateY(-50%) scale(1, 0);
}

.menuList-links > li.has-submenu .icon-plus-submenu:hover {
  border: 1px solid var(--shop-color-border);
}

ul.menuList-links li ul.submenu-links {
  display: none;
}

ul.submenu-links li a {
  padding: 8px 0 8px 0px;
}

li.has-submenu.level0 > ul.submenu-links {
  border-top: 1px dashed var(--shop-color-border);
}

li.has-submenu.level0 > ul.submenu-links li a {
  padding-left: 12px;
}

li.has-submenu.level1 > ul.submenu-links li a {
  padding-left: 25px;
}

.group-sidebox {
  margin: 0 0 30px;
  position: relative;
  padding: 20px 15px;
  border: 1px solid var(--shop-color-border);
}
.group-sidebox .sidebox-title h3 {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 2px solid #323232;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.group-sidebox .sidebox-title h3 span {
  display: none;
}
@media (max-width: 767px) {
  .group-sidebox .sidebox-title h3 {
    text-align: left;
    position: relative;
  }
  .group-sidebox .sidebox-title h3 span {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    width: 25px;
    text-align: center;
    transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -ms-transition: all 0.4s ease-in-out 0s;
  }
  .group-sidebox .sidebox-content-togged {
    display: none;
  }
  .group-sidebox.is-open .sidebox-title h3 span {
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

.group-sidebox-banner {
  margin: 0 0 30px;
}
.group-sidebox-banner .sidebox-banner {
  position: relative;
  overflow: hidden;
  margin: 0;
}
.group-sidebox-banner .sidebox-banner img {
  width: 100%;
  -webkit-transition: all 1s ease-in-out 0s;
  -khtml-transition: all 1s ease-in-out 0s;
  -moz-transition: all 1s ease-in-out 0s;
  -ms-transition: all 1s ease-in-out 0s;
  -o-transition: all 1s ease-in-out 0s;
  transition: all 1s ease-in-out 0s;
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.group-sidebox-banner .sidebox-banner:hover img {
  -webkit-transform: scale(1.2);
  -khtml-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

/*---------- ABOUT - CONTACT -- SEARCH ---------------------------------------------*/
.layoutPage-contact .wrapbox-contact-maps .box-map {
  border: 1px solid #efefef;
}

.layoutPage-contact .wrapbox-contact-maps iframe {
  display: block;
}

.layoutPage-contact .wrapbox-contact-body {
  padding-top: 50px;
  padding-bottom: 50px;
}
.layoutPage-contact .wrapbox-contact-body .box-header-contact h1 {
  font-size: 28px;
  font-weight: 400;
  margin: 0px 0;
}
.layoutPage-contact .wrapbox-contact-body .box-header-contact:after {
  content: "";
  display: block;
  width: 50px;
  height: 4px;
  margin-top: 30px;
  background: #252a2b;
  margin-bottom: 40px;
}
.layoutPage-contact .wrapbox-contact-body .box-info-contact ul {
  padding: 0;
}
.layoutPage-contact .wrapbox-contact-body .box-info-contact li {
  margin-bottom: 30px;
}
.layoutPage-contact .wrapbox-contact-body .box-info-contact li p {
  margin-bottom: 0;
  color: #666;
  font-weight: 400;
}
.layoutPage-contact .wrapbox-contact-body .box-info-contact li p strong {
  font-weight: 600;
  color: #333333;
}
.layoutPage-contact .wrapbox-contact-body .box-send-contact h2 {
  font-weight: 400;
  font-size: 24px;
  margin: 35px 0 40px;
}
.layoutPage-contact .wrapbox-contact-body .box-send-contact h2:after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 30px;
  height: 3px;
  background: #252a2b;
}
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .input-group {
  width: 100%;
  margin: 0 0 20px;
}
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .input-group
  input,
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .input-group
  textarea {
  border: 1px solid #e7e7e7;
  box-shadow: none;
  height: 45px;
  font-weight: 500;
  padding: 0 20px;
  background: #fbfbfb;
  color: #333333;
  width: 100% !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .input-group
  textarea {
  width: 100%;
  height: 130px;
  padding: 20px 20px;
}
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .input-group
  textarea:focus {
  outline: none;
}
.layoutPage-contact
  .wrapbox-contact-body
  .box-send-contact
  .contact-form
  .btn-contact {
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .layoutPage-contact .wrapbox-contact-maps .box-map {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    height: calc(100vh - 15px);
  }
  .layoutPage-contact .wrapbox-contact-maps iframe {
    height: 100% !important;
  }
  .layoutPage-contact .wrapbox-contact-body {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .layoutPage-contact .wrapbox-contact-maps iframe {
    height: 50vh !important;
  }
  .layoutPage-contact .wrapbox-contact-body {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 767px) {
  .layoutPage-contact .wrapbox-contact-maps iframe {
    height: 50vh !important;
  }
}

.layoutPage-about .wrapbox-sidebar-page {
  margin-bottom: 50px;
}

.layoutPage-about .wrapbox-content-page {
  margin-bottom: 50px;
}

/*---------- BLOG - ARTICLE ----------------------------------------------*/
.layoutPage-blog .wrapbox-blog-content,
.layoutPage-blog .wrapbox-blog-sidebar {
  margin-bottom: 50px;
}

.list-article-content .news-content {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.blog-posts .blog-loop {
  margin-bottom: 25px;
}

.blog-posts .blog-loop:nth-of-type(3n + 1) {
  clear: both;
}

.blog-posts .blog-post .blog-post-thumbnail {
  display: block;
  overflow: hidden;
}

.blog-posts .blog-post .blog-post-thumbnail img {
  -webkit-transition: all 1s ease-in-out 0s;
  -khtml-transition: all 1s ease-in-out 0s;
  -moz-transition: all 1s ease-in-out 0s;
  -ms-transition: all 1s ease-in-out 0s;
  -o-transition: all 1s ease-in-out 0s;
  transition: all 1s ease-in-out 0s;
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.blog-posts .blog-post .blog-post-thumbnail:hover img {
  -webkit-transform: scale(1.2);
  -khtml-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.blog-posts .blog-post .blog-post-title {
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  font-family: inherit;
}

.blog-posts .blog-post .blog-post-meta {
  margin-bottom: 15px;
  color: #999;
}

.blog-posts .blog-post .blog-post-meta > span:after {
  content: "/";
  padding-left: 4px;
}

.blog-posts .blog-post .blog-post-meta > span:last-child:after {
  content: "";
}

.blog-posts .blog-post .blog-post-meta a {
  color: #999;
}

.blog-posts .blog-post .blog-post-meta a:hover {
  color: #000000;
}

/* article */
.box-article-heading h1 {
  margin: 20px 0;
  font-size: 24px;
}

.box-article-heading .header-page {
  z-index: 9;
}

.box-article-heading .header-page:after {
  background: #000;
}

ul.article-info-more {
  padding-left: 0;
}

ul.article-info-more li {
  opacity: 0.85;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
}

ul.article-info-more li i {
  margin-right: 5px;
}

.meta-tags .tags-title {
  font-weight: 500;
}

.meta-tags a.tag {
  opacity: 0.8;
  font-style: italic;
  font-size: 14px;
}

.meta-tags a.tag:after {
  content: ",";
  margin: 1px;
}

.post-navigation {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 500;
}

.post-navigation .right {
  float: right;
}

.post-navigation .left {
  float: left;
}

.article-pages,
.meta-tags,
.article-comment .comment_form,
.post-navigation {
  margin-bottom: 40px;
}

.article-pages * {
  max-width: 100% !important;
}

.article-pages ul {
  list-style: initial;
  padding-left: 20px;
}

.article-pages ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.article-pages ul ul {
  list-style-type: circle;
}

.article-pages ul ul ul {
  list-style-type: square;
}

.article-comment .title-bl h2 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 30px;
}

.article-comment .title-bl h2:after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 30px;
  height: 3px;
  background: #252a2b;
}

.article-comment .comment_form .form-group input,
.article-comment .comment_form .form-group textarea {
  box-shadow: none;
  background: #ededed;
  color: #333333;
  border: 0;
  border-radius: 0;
}

.article-comment .comment_form .form-group input {
  padding: 0 20px;
  height: 55px;
}

.article-comment .comment_form .form-group textarea {
  padding: 20px;
  resize: none;
}

.article-comment .comment_form button.btn-rb {
  background: #096743;
  border: 0;
  height: 55px;
  display: inline-block;
  padding: 0 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
}

.article-comment .comment_form button.btn-rb:hover {
  background: #65d1e6;
}

#article h3.author {
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
}

#article .comment-content .comment-user {
  padding: 20px 0px;
  border-bottom: 1px solid #dee2e6;
}
@media (max-width: 767px) {
  #article .comment-content .comment-user {
    padding: 15px 0;
  }
}

#article .comment-content .comment-user:first-child {
  padding-top: 0;
}

#binhluan .date {
  float: right;
  color: #333333;
  font-size: 12px;
}

#binhluan .comment-user {
  padding: 25px 0;
  border-bottom: 1px solid #ededed;
}

/*---------------PAGINATION - 404 - SEARCH PAGE ------------------------------- */
.layoutPage-not {
  min-height: 70vh;
  padding-bottom: 60px;
}
.layoutPage-not .content-pagenot h1 {
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 10px;
}
.layoutPage-not .content-pagenot p.subtext {
  font-weight: 500;
  opacity: 0.66;
}
.layoutPage-not .content-pagenot:after {
  content: "";
  display: block;
  background: #252a2b;
  width: 80px;
  height: 4px;
  margin: 50px auto 0;
}
.layoutPage-not .content-pagenot a {
  font-weight: 500;
  font-size: 16px;
  margin: 30px 0 0;
  display: block;
}

.layoutPage-search {
  margin-bottom: 80px;
}
.layoutPage-search .heading-page {
  position: relative;
  text-align: center;
  padding: 0 0 30px 0;
}
.layoutPage-search .heading-page:after {
  content: "";
  background: #252a2b;
  display: block;
  width: 60px;
  height: 4px;
  margin: 25px auto 0;
}
.layoutPage-search .heading-page h1 {
  font-size: 30px;
  margin: 0 0 10px;
}

.wrapbox-content-search {
  padding-bottom: 30px;
}

.searchPage .subtxt {
  opacity: 0.66;
  margin-top: 15px;
}

.search-field {
  width: 60%;
  display: block;
  margin: auto;
}

input#go {
  width: 55px;
  height: 55px;
  float: right;
  background: url(//theme.hstatic.net/200000299178/1000739054/14/iconsearch.png?v=76)
    #252a2b center no-repeat;
  margin: 0px;
  position: relative;
  top: 0;
  border-radius: 0;
  font-size: 0;
  border: 0;
}

#search .search_box {
  width: calc(100% - 55px);
  outline: none;
  height: 55px;
  padding: 0 20px;
  background: #ededed;
  border: 0;
  box-shadow: none;
}

.expanded-message .message-txt {
  width: 100%;
  padding-top: 25px;
  text-align: center;
}

.expanded-message .message-txt p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.expanded-message h2 {
  font-weight: 500;
  font-size: 22px;
}

.expanded-message .subtext {
  margin-bottom: 40px;
  display: block;
}

.search-item {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  float: left;
}

.search-item .thumb-image {
  display: block;
  width: 80px;
  position: relative;
  overflow: hidden;
  float: left;
}

.search-item .thumb-image img {
  vertical-align: middle;
  -webkit-transition: transform 250ms linear;
  -moz-transition: transform 250ms linear;
  -o-transition: transform 250ms linear;
  transition: transform 250ms linear;
}

.search-item .thumb-image:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: opacity 250ms linear;
  opacity: 0;
  content: "";
}

.search-item .content {
  float: left;
  padding-left: 35px;
  width: calc(100% - 80px);
}

.search-item .content .title {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
}

.search-item .content .caption {
  opacity: 0.66;
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.search-item .content .caption del {
  font-size: 13px;
  margin-left: 15px;
  opacity: 0.5;
}

.subtext-result {
  margin-bottom: 30px;
}

.search-item:hover .thumb-image:after {
  opacity: 1;
}

/* --------------------------------------------------------------------------------
  CART PAGE
  -------------------------------------------------------------------------------- */
.wrapper-cart-detail {
  padding: 35px 0 50px;
}

.layoutPage-cart .heading-page {
  position: relative;
  text-align: center;
  padding: 0 0 30px 0;
}

.layoutPage-cart .heading-page:after {
  content: "";
  background: #252a2b;
  display: block;
  width: 60px;
  height: 4px;
  margin: 25px auto 0;
}

.layoutPage-cart .heading-page h1 {
  font-size: 30px;
  margin: 0 0 10px;
}

.layoutPage-cart .heading-page .count-cart span {
  font-weight: 500;
}

.layoutPage-cart .cart-empty {
  font-size: 20px;
  margin: 30px 0;
}

table.table-cart {
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
}

table.table-cart thead {
  display: none;
}

table.table-cart tr td {
  padding: 20px 0;
  border-top: 1px solid #ededed;
  vertical-align: top;
}

table.table-cart tr:first-child td {
  border-top: 0;
}

table.table-cart tr td.image {
  text-align: center;
  width: 110px;
}

table.table-cart tr td.image img {
  max-width: 100px;
}

table.table-cart tr td.item {
  padding-left: 15px;
}

table.table-cart tr td.item h3 {
  margin: 0 0 5px;
  font-size: 16px;
  font-weight: 500;
  padding-right: 30px;
}

table.table-cart tr td.item p {
  margin-bottom: 5px;
}

table.table-cart tr td.item p .pri {
  font-weight: 500;
}

table.table-cart tr td.item p del {
  font-size: 12px;
}

table.table-cart tr td.item .price {
  margin: 0;
  float: right;
}

table.table-cart tr td.item .price span.text {
  display: none;
}

table.table-cart tr td.item .price span {
  font-size: 16px;
  padding-right: 0;
  font-weight: 500;
}

table.table-cart tr td.item .variant {
  margin-bottom: 5px;
}

table.table-cart tr td.item .error {
  display: none;
  width: 100%;
  float: left;
  clear: both;
  margin: 6px 0 0;
  font-size: 12px;
  color: red;
}

table.table-cart tr td.remove {
  text-align: right;
}

.qty-click input.item-quantity {
  font-weight: 500;
  font-size: 15px;
  height: 25px;
  padding: 0;
  text-align: center;
  width: 35px;
  background: #ededed;
  border: 1px solid #dadbdd;
  border-radius: 0;
  float: left;
  -webkit-appearance: none;
}

.qty-click {
  float: left;
}

.qty-click button.qty-btn {
  background: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #abafb2;
  height: 25px;
  padding: 0;
  text-align: center;
  width: 25px;
  border: 1px solid #dadbdd;
  border-radius: 0;
  float: left;
  -webkit-appearance: none;
}

.qty-click button.qtyplus.qty-btn {
  border-left: none;
}

.qty-click button.qtyminus.qty-btn {
  border-right: none;
}

.qty-click button.qty-btn:focus {
  outline: none;
}

.checkout-note textarea {
  border-radius: 0;
  background: #ededed;
  color: #333333;
  font-size: 15px;
  padding: 20px 15px;
  outline: none;
  width: 100% !important;
  min-height: 130px;
  font-weight: 500;
  border: 1px solid transparent;
  transition: all 150ms linear;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.cart-buttons a,
.cart-buttons .btn-checkout,
.cart-buttons .btn-update {
  display: inline-block;
  vertical-align: top;
  padding: 19px 20px;
}

.cart-buttons .link-continue {
  margin-left: 0;
}

.link-continue {
  margin: 10px 0 0;
  text-align: center;
}

.link-continue a {
  color: #096743;
}
.link-continue a:hover {
  color: #dd0c1b;
}

.link-continue a i {
  margin-right: 4px;
}

.sidebox-order {
  background: #fff;
  padding: 15px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #e1e3e4;
  border-radius: 2px;
}

.sidebox-order div[class^="sidebox-order_"] {
  margin-bottom: 15px;
}

.sidebox-order div[class^="sidebox-order_"]:last-child {
  margin-bottom: 0px;
}

.sidebox-order_title h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0;
}

.sidebox-order_total {
  font-size: 16px;
  color: #5c5c5c;
  font-weight: 500;
  padding: 15px 0;
  border-top: 1px dotted #dfe0e1;
  border-bottom: 1px dotted #dfe0e1;
}

.sidebox-order_total p {
  margin: 0;
}

.sidebox-order_total p .total-price {
  float: right;
  color: #096743;
  font-size: 24px;
}

.sidebox-order_action .btncart-checkout {
  border-radius: 0;
  color: white;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding: 12px 20px;
  background: red;
  border-color: red;
}
.sidebox-order_action .btncart-checkout:hover {
  color: white;
  border-color: red;
  background: red;
}

.sidebox-group h4 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}

.sidebox-policy ul li {
  display: block;
  line-height: 25px;
  position: relative;
  padding-left: 20px;
  overflow: hidden;
}

.sidebox-policy ul li:before {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  content: "\f178";
  font-size: 10px;
  left: 0;
  opacity: 1;
  position: absolute;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
}

/*--------------------------------------------------------------------------------
  ACCOUNT
  --------------------------------------------------------------------------------*/
.wrapbox-heading-account {
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.wrapbox-heading-account .header-page {
  position: relative;
}

.wrapbox-heading-account .header-page:after {
  content: "";
  background: #000;
  display: block;
  width: 60px;
  height: 4px;
  margin-top: 70px;
}

.wrapbox-heading-account .header-page h1 {
  font-size: 54px;
  font-weight: 500;
  margin: 0px 0;
  line-height: initial;
}

.wrapbox-heading-account .header-page p {
  font-size: 14px;
  opacity: 0.66;
  margin: 20px 0 0 0;
  position: relative;
}

.wrapbox-heading-account .header-page p span {
  font-weight: 500;
}

.userbox h2 {
  font-size: 20px;
  margin: 0 0 20px;
  font-weight: 500;
}

.large_form {
  margin-bottom: 30px;
}

.large_form.large_form-mr10 {
  margin-bottom: 10px;
}

.icon-field {
  display: none;
}

.userbox input[type="email"],
.userbox input[type="password"],
.userbox input[type="text"],
.userbox input[type="tel"] {
  width: 100%;
  height: 55px;
  border: 1px solid transparent;
  background: #ededed;
  color: #333333;
  padding: 0 20px;
  font-weight: 500;
  -webkit-appearance: none;
}

.userbox input[type="email"]:focus,
.userbox input[type="password"]:focus,
.userbox input[type="text"]:focus,
.userbox input[type="tel"]:focus {
  background: #fff;
  border-color: #ededed;
}

.action_account_custommer .action_bottom,
.action_account_custommer .req_pass {
  display: inline-block;
  vertical-align: middle;
}

.action_account_custommer .req_pass {
  margin-left: 20px;
}

.userbox .action_bottom {
  padding: 0;
}

.userbox .action_bottom .btn {
  display: inline-block;
  border-radius: 0;
  padding: 0 30px;
  height: 55px;
  line-height: 55px;
  text-transform: uppercase;
  font-weight: 500;
}

.req_pass {
  color: #959898;
  font-weight: 500;
}

.come-back {
  margin-top: 35px;
  display: block;
  font-weight: 500;
}

.come-back i {
  margin-right: 20px;
}

.userbox .errors a {
  color: #096743;
}

.userbox .account-method-login {
  margin: 0 0 60px 0;
}
.userbox .account-method-login button {
  background: #a9a9a9;
}
@media (max-width: 991.9px) {
  .userbox .account-method-login button {
    min-width: 140px;
    padding: 11px;
  }
}
.userbox .account-method-login button ~ button {
  margin: 0 0 0 15px;
}
.userbox .account-method-login button.active,
.userbox .account-method-login button:hover {
  background: #dd0c1b;
}

@-moz-keyframes ripple {
  5%,
  100% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  5%,
  100% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
}

@keyframes ripple {
  5%,
  100% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
}

#form-gender input[type="radio"] {
  display: none;
}

#form-gender input[type="radio"] + label {
  position: relative;
  cursor: pointer;
  padding-left: 28px;
  margin: 0 20px 0 0;
}

#form-gender input[type="radio"] + label:before,
input[type="radio"] + label:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#form-gender input[type="radio"] + label:before {
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #096743;
  -moz-box-shadow: inset 0 0 0 18px #efefef;
  -webkit-box-shadow: inset 0 0 0 18px #efefef;
  box-shadow: inset 0 0 0 18px #efefef;
}

#form-gender input[type="radio"] + label:after {
  top: 49%;
  left: 9px;
  width: 54px;
  height: 54px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.3);
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

#form-gender input[type="radio"]:checked + label:before {
  -moz-box-shadow: inset 0 0 0 4px #efefef;
  -webkit-box-shadow: inset 0 0 0 4px #efefef;
  box-shadow: inset 0 0 0 4px #efefef;
}

#form-gender input[type="radio"]:checked + label:after {
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -moz-animation: ripple 1s none;
  -webkit-animation: ripple 1s none;
  animation: ripple 1s none;
}

/* --------------------- order ---- account -- address -----------------------  */
.layout-info-account {
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .layout-info-account {
    padding-bottom: 20px;
  }
}
.layout-info-account .content-page-order {
  width: 100%;
}
@media (max-width: 767px) {
  .layout-info-account .content-page-order {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .layout-info-account.detail-order {
    padding-bottom: 0px;
  }
}

.title-infor-account {
  padding: 30px 15px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 60px;
}

.title-infor-account h1 {
  font-size: 42px;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.title-infor-account h1:after {
  content: "";
  display: block;
  width: 60px;
  height: 4px;
  margin: 50px auto 0;
  background: #252a2b;
}

.title-detail {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  border-bottom: 1px solid #ededed;
  padding-bottom: 8px;
}

.sb-title:after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 30px;
  height: 3px;
  background: #252a2b;
}

.name_account {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  margin: 10px 0 5px;
}

#customer_sidebar p {
  margin-bottom: 0;
  font-size: 14px;
}

.customer-table-wrap {
  background: #f7f7f7;
  padding: 10px;
}

.customer-table-bg {
  background: #fff;
  padding: 10px;
}

.customer-table-bg p {
  margin: 0;
}

#customer_orders table thead th,
#order_details tr th {
  font-weight: 500;
  font-size: 14px;
}

#customer_orders table tr a {
  font-weight: 500;
  float: left;
  width: 85%;
  margin-left: 3px;
  line-height: 20px;
  opacity: 0.66;
}

#customer_orders table,
#order_details {
  width: 100%;
  background: #fff;
  margin-bottom: 0;
}

#customer_orders table tr,
#order_details tr {
  height: 25px;
  line-height: 30px;
}

#customer_orders table tr.odd,
#order_details tr {
  background: #fff;
}

#customer_orders table tr td,
#order_details tr td {
  vertical-align: middle;
  border-top: none;
  font-size: 14px;
}

#order_details tr td {
  line-height: 21px;
}

#order_details tr td a,
#order_details b {
  font-weight: 500;
}

#order_details tr td.total b {
  font-weight: 500;
}

#order_details tr td.total,
#order_details tr th.total {
  padding-right: 8px;
}

#order_details tr.order_border {
  border-top: 1px solid #ededed;
}

#order_details tr td.order-image {
  width: 70px;
}

#order_details tr td.order-image a {
  display: block;
  width: 60px;
  border: 1px solid #f7f7f7;
}

a#view_address,
a#update_profile {
  font-weight: 500;
  opacity: 0.8;
}

#customer_orders,
#order_payment,
#order_shipping {
  margin-bottom: 30px;
  margin-top: 30px;
}

@media (max-width: 767px) {
  #order_payment {
    margin-bottom: 0;
  }
  #order_shipping {
    margin-top: 0;
  }
  #order_shipping .address {
    margin: 0;
  }
}

.customer-table-bg .table > tbody > tr > th {
  border-top: none;
}

.detail-table-order {
  margin-top: 30px;
}

.name-order {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.order_section_title {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 10px;
  padding: 10px 0px;
  position: relative;
  text-transform: uppercase;
}

.order_date {
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}

.text_status {
  font-weight: 500;
}

.address p {
  margin-bottom: 5px;
}

.account-order .address {
  margin: 0px 0px 30px;
  padding: 10px;
  background-color: #fbfbfb;
}

span.variant_acc {
  font-size: 13px;
  opacity: 0.8;
}

.alert-info {
  border-radius: 0;
  margin-bottom: 0;
}

.address_title {
  margin-bottom: 0;
  font-size: 16px;
  padding: 10px 70px 10px 10px;
  background-color: #d9edf7;
  border-color: #bce8f1;
  position: relative;
}

.address_table {
  margin-bottom: 10px;
  border-top: 0px solid #ddd;
  background: #fafafa;
  padding: 15px;
}
.address_table input[name="address[default]"] {
  margin: 0 10px 0 0;
}

#add_address input[name="address[default]"] {
  margin: 0 10px 0 0;
}

.address_title h3 {
  margin: 0;
  font-size: 14px;
}

.account-address .note {
  border: none;
  padding: 0;
}

p.address_actions.text-right {
  margin: 7px 5px 0px 0;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
}

.action_link a {
  text-transform: uppercase;
  font-size: 15px;
}

span.action_link {
  padding: 5px;
}

.view_address p > b {
  font-weight: 500;
  font-size: 14px;
}

.view_address p {
  margin: 5px 0;
  line-height: 15px;
  font-size: 14px;
}

.add-new-address {
  padding-bottom: 10px;
  display: inline-block;
  padding: 10px;
  background: #096743;
  color: #fff !important;
  text-transform: uppercase;
}

.add-new-address:focus {
  color: #fff;
}

.account-address .input-group-addon {
  display: none;
}

.input-group {
  margin: 15px 0px;
}

.input-group .input-group-addon {
  padding: 6px 10px;
  border-radius: 0;
  color: #616161;
  background-color: #ebebeb;
  border-color: #ebebeb;
  width: 40px;
  text-align: center;
}

.input-group .form-control {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #ebebeb;
}

.icon-user:after {
  content: url(//hstatic.net/0/0/global/design/theme-default/userico.png);
}

.icon-envelope:after {
  content: url(//hstatic.net/0/0/global/design/theme-default/email.png);
}

.icon-shield:after {
  content: url(//hstatic.net/0/0/global/design/theme-default/pass.png);
}

.layout-info-account .action_bottom .btn {
  height: 35px;
  line-height: 35px;
  outline: 0;
  margin-right: 5px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 30px;
  border: 0;
  background: #096743;
  color: #fff;
  display: inline-block;
  border-radius: 0;
}

.AccountTitle {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
}

.AccountList ul > li > a {
  display: block;
  position: relative;
  padding: 5px 0px;
}

.AccountList ul > li > a:before {
  content: "\f192";
  font-family: "Font Awesome 5 Pro";
  font-size: 8px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: 2px;
}

#order_cancelled {
  margin-bottom: 15px;
}

#order_cancelled h5 {
  margin: 0;
}

#update_profile {
  max-width: 470px;
}
#update_profile #gender span ~ span {
  margin: 0 0 0 20px;
}
#update_profile #gender input {
  margin: 0 5px 0 0;
}
#update_profile #is-accept-marketing input {
  margin: 0 10px 0 0;
}

/*---------------------------------------------------------------------------------
  RESPONSIVE
  ---------------------------------------------------------------------------------*/
@media (min-width: 992px) and (max-width: 1199px) {
  /*index */
  .box-banner-inf {
    width: 80%;
    z-index: 4;
  }
  .list-slider-banner.owl-carousel .owl-nav .owl-next {
    right: 65px;
  }
  .list-slider-banner.owl-carousel .owl-nav .owl-prev {
    left: 65px;
  }
  /*account*/
  .wrapbox-content-account {
    padding: 100px 60px;
  }
  .wrapbox-heading-account .header-page {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .wrapbox-heading-account {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-right: 1px solid #ededed;
    height: 100vh;
    padding: 0;
  }
  .wrapbox-heading-account .header-page {
    padding-bottom: 70px;
  }
  /**** INDEX ****/
  .wrap-pd-infor.box_stick {
    position: -webkit-sticky;
    position: sticky !important;
    max-height: 100vh;
    top: 0;
    border-right: 1px solid #ededed;
  }
  .wrap-pd-infor .inf-content {
    padding: 100px 15px;
    max-width: 80%;
    margin: auto;
  }
  /**** COLLECTION ****/
  .sidebar-fix {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    margin-top: 50px;
  }
  /**** PRODUCT DETAIL ****/
  .icon-pr-fix.out-with-you {
    opacity: 0;
  }
  .product-content-desc {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
  }
  /**** BLOG ****/
  .list-article-content .news-content {
    height: 50vh;
  }
  /**** FOOTER ****/
  .top-footer .area_newsletter {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
  }
  .top-footer .area_newsletter .title_newsletter {
    width: 32%;
    border-right: 1px solid #e7e7e7;
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .wrapbox-content-account {
    padding: 100px;
  }
  .wrapbox-heading-account .header-page {
    width: 70%;
  }
  .block-banner-category img {
    -webkit-transition: -webkit-transform 8s
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: -webkit-transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      -webkit-transform 8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .block-banner-category:hover img {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  .box-banner-inf {
    width: 80%;
    z-index: 4;
  }
  .top-footer .area_newsletter .title_newsletter {
    width: 26%;
  }
  .wrapbox-content-search {
    padding: 0 100px 30px;
  }
  #homepage_slider:not(.owl-loaded),
  .list-slider-banner.owl-carousel:not(.owl-loaded) {
    min-height: 300px;
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  table.table-cart tr td.remove {
    position: absolute;
    right: 15px;
  }
  .sidebarCart-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .wrapbox-heading-account {
    height: 50vh;
    border-bottom: 1px solid #ededed;
  }
  .wrapbox-heading-account .header-page {
    width: 80%;
    text-align: center;
    padding-bottom: 50px;
  }
  .wrapbox-heading-account .header-page:after {
    margin: 50px auto 0;
  }
  .wrapbox-content-account {
    padding: 100px 80px;
  }
  /* INDEX */
  .wrap-pd-infor.box_stick {
    height: 50vh;
  }
  .box-banner-inf {
    width: 70%;
    z-index: 4;
    text-align: center;
  }
  .wrap-pd-infor .inf-content {
    padding: 100px 15px;
    max-width: 80%;
    margin: auto;
  }
  /* COLLECTION */
  .no-product {
    text-align: center;
  }
  /* PRODUCT DETAIL*/
  .icon-pr-fix {
    position: absolute;
    bottom: 25px;
  }
  .product-zoom.icon-pr-fix {
    right: 15px;
    left: initial;
    margin: 0;
  }
  .gallery-index.icon-pr-fix {
    display: none;
  }
  .divzoom_main .product-thumb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .box__product-gallery {
    height: 70vh;
    overflow: visible;
  }
  .slide_product {
    width: 100%;
    height: 100%;
  }
  .slide_product li.gallery-item {
    margin-bottom: 0;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .box__product-gallery.scroll .site-box-content:after {
    content: "flickity";
    display: none;
  }
  /* CART */
  .checkout-note textarea {
    margin-bottom: 20px;
  }
  .sidebox-order_title h3 {
    font-size: 18px;
  }
  .sidebox-order_total p .total-price {
    font-size: 20px;
  }
  .sidebox-order_text {
    font-size: 12px;
  }
  .sidebox-order_action .btncart-checkout {
    font-size: 8px 15px;
  }
  /* BLOGS */
  .list-article-content .news-content {
    height: 50vh;
  }
  /* Footer */
  footer.footer .col-lg:nth-of-type(2n + 1) {
    clear: both;
  }
}

@media (max-width: 991px) {
  .header-wrap-icon > span.icon-account,
  .header-wrap-icon > span.icon-search {
    display: none !important;
  }
  .block .title_block {
    position: relative;
    display: inline-block;
    margin: 0 0 5px;
  }
  .block .title_block span {
    display: block;
    top: 8px;
    right: 10px;
    position: absolute;
  }
  .block .block_content {
    display: none;
  }
  .filter-default {
    padding: 0 15px;
  }
  .product-image-detail {
    width: 100%;
    padding: 0;
  }
  .flickity-page-dots {
    bottom: 15px;
    z-index: 2;
  }
  .product-title h1 {
    font-size: 26px;
    margin-top: 20px;
  }
  .caption_banner {
    padding: 0 15px;
    bottom: 10px;
  }
  .caption_banner h3 {
    font-size: 18px;
  }
  /* collection */
  .wrap-collection-title .heading-collection {
    margin: 0;
  }
  .wrap-collection-title .heading-collection h1 {
    font-size: 20px;
    text-align: center;
    margin: 20px 0;
  }
  .no-product {
    text-align: center;
  }
  .left-module {
    margin: 20px 0 0;
  }
  .wrapper-collection-1 .pro-loop:nth-child(odd),
  .wrapper-collection-2 .pro-loop:nth-child(odd),
  #collection-body .pro-loop:nth-child(odd) {
    padding-right: 7.5px;
  }
  .wrapper-collection-1 .pro-loop:nth-child(even),
  .wrapper-collection-2 .pro-loop:nth-child(even),
  #collection-body .pro-loop:nth-child(even) {
    padding-left: 7.5px;
  }
  /* Blog Article */
  .blog-loop:nth-of-type(2n + 1) {
    clear: both;
  }
  .main-body:not(.layoutProduct_scroll)
    #section-order-content
    .inner-section-order-content
    .page-orders
    .sticky-right.active {
    z-index: 99999;
  }
}

@media (max-width: 767px) {
  .wrapbox-heading-account {
    padding: 30px 15px;
    border-bottom: 1px solid #ededed;
  }
  .wrapbox-heading-account .header-page {
    text-align: center;
  }
  .wrapbox-heading-account .header-page h1 {
    font-size: 34px;
  }
  .wrapbox-heading-account .header-page:after {
    margin: 20px auto 0;
  }
  .wrapbox-content-account {
    padding: 60px 15px;
  }
  .heading-page {
    position: relative;
    text-align: center;
    padding: 0 0 30px 0;
  }
  .wrapbox-content-page-contact {
    padding: 40px 15px;
  }
  .breadcrumb-shop {
    background: #ffffff;
  }
  .breadcrumb-shop .breadcrumb-arrows {
    padding: 10px 0 5px;
  }
  /* index */
  .list-slider-banner.owl-carousel .owl-nav.disabled,
  .list-slider-banner.owl-carousel .owl-nav {
    display: none;
  }
  .list-slider-banner .caption_banner_slide {
    padding: 10px 15px;
  }
  .list-slider-banner .caption_banner_slide h3 {
    font-size: 16px;
  }
  .wrapper-heading-home h2 {
    font-size: 28px;
  }
  .wrap-collection-title h2,
  .wrap-collection-title .link-more {
    font-size: 24px;
  }
  .wrap-collection-title.image-resize {
    padding-top: 0px;
  }
  .wrapper-home-banner .home-banner-pd {
    padding: 0 5px;
    margin-bottom: 0px;
  }
  .box-banner-inf {
    width: 100%;
    padding: 50px 15px;
    z-index: 4;
    text-align: center;
  }
  .wrap-pd-infor.box_stick {
    height: 50vh;
  }
  .wrap-pd-infor .inf-content {
    padding: 50px 30px;
    max-width: 100%;
    margin: auto;
  }
  .box-banner-inf h2 {
    font-size: 40px;
  }
  .box-banner-inf a.button {
    margin-top: 33px;
  }
  #homepage_slider .owl-dots {
    bottom: 8px;
  }
  /* product detail*/
  .product-detail-wrapper {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .product-image-detail {
    border-bottom: 1px solid #ededed;
  }
  .icon-pr-fix {
    position: absolute;
    bottom: 25px;
  }
  .product-sharing.icon-pr-fix {
    left: 15px;
  }
  .product-zoom.icon-pr-fix {
    right: 15px;
    left: initial;
    margin: 0;
  }
  .gallery-index.icon-pr-fix {
    display: none;
  }
  .divzoom_main .product-thumb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .box__product-gallery {
    overflow: visible;
    min-height: 450px;
    display: flex;
    align-items: center;
  }
  .slide_product {
    width: 100%;
    height: 100%;
    transition: opacity 300ms linear, transform 300ms linear;
  }
  .box__product-gallery .flickity-enabled .flickity-viewport {
    transition: height 300ms linear;
  }
  .slide_product li.gallery-item {
    width: 100%;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
  }
  .box__product-gallery.scroll .site-box-content:after {
    content: "flickity";
    display: none;
  }
  .slide_product li.gallery-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    font-family: "object-fit: cover";
    pointer-events: none;
  }
  .product-image-detail .flickity-page-dots .dot {
    transition: all 300ms linear;
  }
  .slide_product .flickity-page-dots {
    bottom: 15px;
    z-index: 8;
  }
  .input-bottom {
    width: 54px;
    float: left;
    margin-right: 15px;
  }
  .input-bottom input {
    width: 54px;
    height: 45px;
    border: 1px solid #d1d1d1;
    text-align: center;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .add-cart-bottom {
    font-weight: 500;
    height: 45px;
    width: calc(100% - 70px);
    border-radius: 0;
    float: left;
    text-transform: uppercase;
  }
  .list-productRelated .pro-loop-title .product-resize,
  .list-productRelated .pro-loop-title .wrap-collection-title.image-resize {
    height: initial !important;
  }
  .list-productRelated .wrap-collection-title.image-resize {
    padding-bottom: 45px;
  }
  .list-productRelated .wrap-collection-title .block-pding:after {
    content: "";
    display: block;
    width: 50px;
    height: 4px;
    margin: 30px auto auto;
    background: #252a2b;
  }
  .list-productRelated .pro-loop:nth-child(odd) {
    padding-right: 7.5px;
  }
  .list-productRelated .pro-loop:nth-child(even) {
    padding-left: 7.5px;
  }
  .product-action-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background: #fff;
    display: block;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    padding: 8px 10px;
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
  }
  .product-action-bottom.move-bottom {
    bottom: -100%;
  }
  .layoutProduct_scroll.scroll-down .product-action-bottom {
    padding-bottom: 60px;
  }
  .layoutProduct_scroll.scroll-down
    #section-order-content
    .inner-section-order-content
    .page-orders
    .sticky-right {
    bottom: 44px;
  }
  /******* blog -article *******/
  .list-article-content .news-content {
    min-height: 250px;
    height: 50vh;
  }
  .article-body h2.title-article {
    font-size: 24px;
  }
  .sidebarblog-title h2 {
    text-align: center;
    margin-bottom: 10px;
  }
  .blog-posts .blog-post .blog-post-thumbnail {
    margin-bottom: 20px;
  }
  /* CART PAGE */
  .layoutPage-cart .heading-page h1 {
    font-size: 25px;
    margin: 0 0 10px;
  }
  table.table-cart {
    margin-bottom: 25px;
    border: 0;
  }
  table.table-cart thead tr {
    display: none;
  }
  table.table-cart tr td {
    border: 0;
    padding: 0 5px;
  }
  table.table-cart tbody > tr {
    background: #ffffff;
    padding: 20px 0;
    display: block;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    margin-bottom: 10px;
  }
  table.table-cart tr td.image {
    width: 80px;
    float: left;
  }
  table.table-cart tr td.image img {
    max-width: 70px;
    background: #fff;
  }
  table.table-cart tr td.item {
    width: calc(100% - 80px);
    float: left;
    padding-left: 5px;
  }
  table.table-cart tr td.item h3 {
    margin: 0 0 5px;
    display: block;
    padding-right: 20px;
  }
  table.table-cart tr td.qty {
    padding: 0px;
    float: right;
    margin-right: 8px;
    text-align: right;
    position: absolute;
    right: 0px;
    bottom: 35%;
  }
  table.table-cart tr td.item .price {
    width: 100%;
    float: left;
    text-align: left;
    margin: 15px 0 0;
  }
  table.table-cart tr td.item .price del {
    margin-top: 2px;
  }
  table.table-cart tr td.item .price span.text {
    display: inline-block;
    font-size: 12px;
  }
  table.table-cart tr td.remove a {
    position: absolute;
    top: 22px;
    right: 0;
  }
  table.table-cart tr:last-child td {
    border: none;
  }
  .checkout-note textarea {
    margin-bottom: 20px;
  }
  .cart-buttons .btn-checkout,
  .cart-buttons a,
  .cart-buttons .btn-update {
    font-size: 10px;
    margin: 0;
    padding: 15px 8px;
  }
  /* SEARCH ++	ACCOUNT */
  .search-field {
    width: 100%;
  }
  .title-infor-account h1 {
    font-size: 30px;
  }
  .title-infor-account h1:after {
    margin-top: 32px;
  }
  .AccountSidebar {
    margin-bottom: 40px;
  }
  .table-responsive {
    padding: 0 8px 10px;
  }
  /* footer*/
  .main-footer {
    padding: 20px 0;
  }
  .footer .collapse {
    display: none;
    visibility: hidden;
  }
  .footer .collapse.in {
    display: block;
    visibility: visible;
  }
  .main-footer .footer-content {
    display: none;
  }
  .main-footer .footer-col {
    border-bottom: 1px solid #efefef;
    margin-bottom: 0;
  }
  .main-footer .footer-title {
    line-height: 20px;
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0px;
    padding: 10px 0;
  }
  .main-footer h4.footer-title:before {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    position: absolute;
    top: 0px;
    right: 5px;
    text-align: center;
    line-height: 36px;
  }
  .main-footer h4.footer-title.active:before {
    content: "\f106";
  }
  .main-footer .col-lg:last-child .footer-col {
    border-bottom: none;
  }
  .breadcrumb-shop .breadcrumb-arrows li {
    font-size: 10px;
  }
  .list-productRelated .content-product-list > div:nth-child(2n) ~ div {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .list-productRelated .content-product-list > div:nth-child(4n) ~ div {
    margin-top: 30px;
  }
}

.slide_product {
  padding: 0;
  margin: 0;
}

/* _________ */
@media (min-width: 1200px) {
  .modal-lg {
    width: 1100px;
  }
}

/*--------------------------------------------------------------------------------
  ADDTHIS ** SHARING
  --------------------------------------------------------------------------------*/
.fb_dialog_advanced.fb_customer_chat_bubble_animated_no_badge {
  right: 12px !important;
}

.addThis_listSharing {
  position: fixed;
  bottom: 85px;
  z-index: 999;
  right: -15px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.addThis_listSharing.is-show {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.addThis_listSharing .addThis_item {
  margin-bottom: 10px;
  margin-right: 24px;
}
.addThis_listSharing .addThis_item .addThis_item--icon {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 44px;
  height: 44px;
  line-height: 44px;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.addThis_listSharing .addThis_item .addThis_item--icon .tooltip-text {
  position: absolute;
  top: 4px;
  right: 55px;
  z-index: 9;
  height: 32px;
  line-height: 26px;
  padding: 3px 10px;
  width: auto;
  border-radius: 5px;
  font-size: 11px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-color: black;
  visibility: hidden;
  opacity: 0;
  -ms-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.addThis_listSharing .addThis_item .addThis_item--icon .tooltip-text:after {
  content: "";
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  -ms-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.addThis_listSharing .addThis_item .addThis_item--icon:hover {
  text-decoration: none;
  opacity: 0.9;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), 0 4px 15px rgba(0, 0, 0, 0.13);
}
.addThis_listSharing .addThis_item .addThis_item--icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.actionToolbar_mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  height: 45px;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  background: #fff;
  padding: 5px 10px;
}
.actionToolbar_mobile ul.actionToolbar_listing li {
  width: 20%;
}
.actionToolbar_mobile ul.actionToolbar_listing li a {
  display: block;
  color: #696969;
  font-size: 12px;
  text-align: center;
  position: relative;
}
.actionToolbar_mobile ul.actionToolbar_listing li a svg {
  fill: #696969;
  width: 35px;
  height: 35px;
}
.actionToolbar_mobile
  ul.actionToolbar_listing
  li.actionToolbar_chatbot
  a
  .messenger_absolute {
  margin-top: -25px;
}
.actionToolbar_mobile ul.actionToolbar_listing li.actionToolbar_chatbot a svg {
  width: 44px;
  height: 44px;
}

@media (max-width: 767px) {
  .mainfooter-toolbar,
  .mainfooter-order {
    padding-bottom: 45px;
  }
  .mainfooter-product {
    padding-bottom: 60px;
  }
  .layoutProduct_scroll.scroll-down .actionToolbar_product,
  .layoutProduct_scroll.scroll-down .actionToolbar_order {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .actionToolbar_product,
  .actionToolbar_order {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

/* - Harafunnel mobile */
.harafunnel-mb {
  position: fixed;
  right: 10px;
  bottom: 60px;
  z-index: 9999;
  width: 65px;
  height: 65px;
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  transition: box-shadow 150ms linear;
}
.harafunnel-mb:hover {
  box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
}
.harafunnel-mb a {
  display: block;
}
.harafunnel-mb a svg {
  width: 100%;
  height: 100%;
}

body.cart-open .harafunnel-mb {
  z-index: 9;
}

/* - Modal contact form */
.modal-addThis {
  background: rgba(3, 17, 27, 0.7);
  -webkit-transition: opacity 0.35s linear;
  -o-transition: opacity 0.35s linear;
  transition: opacity 0.35s linear;
}
.modal-addThis.fade .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.modal-addThis.fade.show .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal-contactform .modal-header h4.modal-title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.modal-contactform .modal-header button.close {
  font-size: 30px;
  padding: 15px;
}

.modal-contactform .modal-body.body-popupform .form-control {
  background: #fbfbfb;
  border-radius: 0;
}
.modal-contactform .modal-body.body-popupform .form-control:focus {
  background: #fff;
}

@media (min-width: 768px) {
  .modal-contactform .modal-dialog {
    max-width: 605px;
  }
}

/* modal-succes */
.modal-succesform .modal-body .modal-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #096743;
}

.modal-succesform .modal-body p:not(.modal-title) {
  font-size: 15px;
  margin: 10px 0 5px;
}

.modal-succesform .modal-body p.txtloading {
  color: #aaa;
  font-size: 14px;
}

@media (min-width: 768px) {
  .modal-succesform .modal-dialog {
    max-width: 450px;
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -moz-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -moz-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.modal-icon.sweet-alert {
  padding-top: 15px;
}

.animateSuccessTip {
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  animation: animateSuccessLong 0.75s;
}

.sa-icon.sa-success.animate::after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.sweet-alert .sa-icon.sa-success {
  width: 80px;
  height: 80px;
  border: 4px solid #4cae4c;
  border-radius: 50%;
  margin: 20px auto 0;
  position: relative;
  box-sizing: content-box;
}

.sweet-alert .sa-icon.sa-success::before,
.sweet-alert .sa-icon.sa-success::after {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -o-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.sweet-alert .sa-icon.sa-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0px 60px;
  -moz-transform-origin: 0px 60px;
  -o-transform-origin: 0px 60px;
  transform-origin: 0px 60px;
}

.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(92, 184, 92, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #5cb85c;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* - Modal popup contact form */
.modal-popupContact {
  color: #333;
  background: rgba(3, 17, 27, 0.7);
  -webkit-transition: opacity 0.35s linear;
  -o-transition: opacity 0.35s linear;
  transition: opacity 0.35s linear;
}

.modal-popupContact .close-popup-contact {
  background-color: rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  outline: none;
}

.modal-popupContact .close-popup-contact svg {
  display: block;
  margin: auto;
  height: 30px;
  width: 14px;
  text-align: center;
  fill: #f3f3f3;
}

.modal-popupContact .close-popup-contact:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-popupContact.fade .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-popupContact.fade .modal-dialog-centered {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.modal-popupContact.fade.show .modal-dialog-centered {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.modal-popupContact.popupBanner .modal-content {
  background-color: transparent;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.modal-popupContact.popupForm .modal-content {
  position: relative;
  overflow: hidden;
  padding: 0;
  display: unset;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e6e6e6;
}

.modal-popupContact.popupForm .modal-content.lazyloaded {
  background-image: url(//theme.hstatic.net/200000299178/1000739054/14/popup_contact_bkg.jpg?v=76);
}

.modal-popupContact.popupForm .modal-content:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #f3f3f3;
  opacity: 0.75;
  width: 58%;
}

.modal-popupContact.popupForm .modal-content .modal-wrapper-contact {
  position: relative;
  padding: 50px 25px;
  float: right;
  width: 58%;
}

.title-popup-contact {
  font-size: 24px;
  margin: 0px 0px 10px;
  font-weight: 500;
}

.message-popup-contact {
  margin-bottom: 25px;
}

.title-adv-popup-contact {
  font-size: 14px;
  margin-bottom: 5px;
}

.list-adv-popup-contact li {
  list-style-type: disc;
  margin-left: 25px;
  font-size: 14px;
}

.popup-form-customer .input-group {
  width: 100%;
  display: block;
  margin-bottom: 0;
}

.popup-form-customer .input-group input {
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
  padding: 10px 20px;
  line-height: 25px;
  background: #fff;
  border: 1px solid #e7e7e7;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.popup-form-customer button.input-group-addon {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0;
  height: 45px;
  padding: 10px 20px;
  line-height: 25px;
}

.popup-form-customer .input-group .input-group-addon {
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  border-color: #096743;
}

.popup-form-customer .input-group .input-group-addon:hover,
.popup-form-customer .input-group .input-group-addon:focus {
  background-color: #fff;
  border-color: #096743;
  color: #096743;
}

.popup-form-customer .succes-popup {
  font-size: 13px;
  min-height: 25px;
  visibility: hidden;
  margin: 0;
  text-align: center;
}

.popup-form-customer .succes-popup.error {
  visibility: visible;
  color: red;
}

.popup-form-customer .succes-popup.success {
  visibility: visible;
  color: #27a208;
}

@media (min-width: 768px) {
  .modal-popupContact .modal-dialog-centered {
    max-width: 650px;
  }
}

@media (max-width: 767px) {
  .modal-popupContact.popupForm .modal-content:before,
  .modal-popupContact.popupForm .modal-content .modal-wrapper-contact {
    width: 100%;
  }
}

/*===== QUICK VIEW DETAIL ================================*/
.wrapper-quickview {
  position: fixed;
  top: 100%;
  left: 0px;
  right: 0px;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  -webkit-transition: all 0.2s ease 0.3s;
  transition: all 0.2s ease 0.3s;
}
.wrapper-quickview .modal-paramlink .paramlink-topbar {
  height: 54px;
  padding: 7px 0 5px;
}
.wrapper-quickview .modal-detailProduct {
  height: calc(100% - 54px);
  overflow: hidden;
}
.wrapper-quickview .modal-detailProduct .modal-dialog {
  margin: 0 auto;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 60px;
  -webkit-transition: height 0.2s ease 0.4s;
  -o-transition: height 0.2s ease 0.4s;
  transition: height 0.2s ease 0.4s;
}
.wrapper-quickview .modal-detailProduct .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  .close-quickview {
  display: none;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2 {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  padding-left: 15px;
  padding-right: 15px;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-title {
  padding: 0 0 20px;
  margin: 0 0 20px;
  width: 100%;
}
@media (max-width: 767px) {
  .wrapper-quickview
    .modal-detailProduct
    .modal-dialog
    .modal-content
    #add-item-form2
    .product-title
    img {
    display: none;
  }
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-title
  .info {
  margin: 15px 0 0 0;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-title
  .info
  h4 {
  font-size: 18px;
  font-weight: bold;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-title
  .info
  p {
  margin: 0;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .select-swatch {
  width: 100%;
  order: 1;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .quickview-des {
  order: 3;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1010;
  margin: auto;
  background: #fff;
  border-top: 1px solid #e7e7e7;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .error-max {
  text-align: center;
  display: none;
  margin: 0;
  color: red;
  padding: 4px 10px 0;
  font-size: 12px;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn {
  padding: 5px 8px;
  -webkit-transition: padding 0.3s ease;
  transition: padding 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn
  .select-quantity
  .box_qty {
  display: -moz-flex;
  display: -webkit-flex;
  display: -o-flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn
  .select-quantity
  .box_qty
  .qty-btn {
  background: transparent;
  border: 1px solid;
  color: #096743;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn
  .select-quantity
  .box_qty
  .qty-btn
  svg {
  fill: #096743;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn
  .select-quantity
  .box_qty
  .quantity-selector {
  border: 0;
  width: 40px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding: 5px;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-actions
  .actions-btn
  .btn-cart-product {
  flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  width: 1%;
  margin-left: 10px;
  border: none;
  float: right;
  background: #096743;
  color: #fff;
  font-size: 0.875rem;
  line-height: 38px;
  border: 1px solid #096743;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  box-shadow: none;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-note {
  position: relative;
  width: 100%;
  order: 2;
  margin: 0 0 20px 0;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-note
  svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #ededed;
  border: 1px solid #cbcbcb;
  border-right: none;
  border-radius: 4px 0 0 4px;
}
.wrapper-quickview
  .modal-detailProduct
  .modal-dialog
  .modal-content
  #add-item-form2
  .product-note
  input {
  width: 100%;
  border: 0 none;
  padding: 0 10px 0 50px;
  line-height: 38px;
  background: #fff;
  color: #333;
  border: 1px solid #cbcbcb;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 4px;
}
@media (max-width: 991px) {
  .wrapper-quickview .modal-detailProduct .modal-dialog {
    padding-bottom: 130px;
  }
}
.wrapper-quickview .modal-detailProduct .quickview-detailProduct--scroll {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 60px;
  -webkit-transition: height 0.2s ease 0.4s;
  -o-transition: height 0.2s ease 0.4s;
  transition: height 0.2s ease 0.4s;
}
.wrapper-quickview .quickview-close {
  position: absolute;
  top: 14px;
  left: 12px;
  width: 25px;
  height: 25px;
  z-index: 99;
}
.wrapper-quickview .quickview-close svg {
  width: 100%;
  height: 100%;
}
.wrapper-quickview .jsQuickview {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 0;
  height: 100%;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.wrapper-quickview .modal-paramlink {
  position: relative;
  border-radius: 0;
  overflow: hidden;
}
.wrapper-quickview .modal-paramlink:before {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: #f7f5f5;
}
.wrapper-quickview .modal-paramlink .paramlink-topbar {
  position: relative;
  display: block;
  border-bottom: 1px solid var(--shop-color-border);
  padding-left: 54px;
  padding-right: 54px;
}
.wrapper-quickview .modal-paramlink .paramlink-topbar .purl-title {
  font-size: 15px;
  margin: 0;
  display: block;
}
.wrapper-quickview .modal-paramlink .paramlink-topbar .purl-title span {
  visibility: initial;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.wrapper-quickview .modal-paramlink .paramlink-topbar .purl-link {
  font-size: 13px;
}
.wrapper-quickview .productCarousel-slider:not(.owl-loaded) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.wrapper-quickview .productCarousel-slider:not(.owl-loaded) .product-gallery {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.wrapper-quickview .quickview-sliderproduct {
  overflow: hidden;
}
.wrapper-quickview .quickview-sliderproduct .owl-nav button {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  margin: 0px;
  font-size: 0;
  height: 35px;
  width: 35px;
  line-height: 35px;
  outline: none;
  text-align: center;
  display: inline-block;
  background: transparent;
}
.wrapper-quickview .quickview-sliderproduct .owl-nav button.owl-prev {
  left: 0px;
}
.wrapper-quickview .quickview-sliderproduct .owl-nav button.owl-next {
  right: 0px;
}
.wrapper-quickview .quickview-sliderproduct .owl-nav button:before {
  content: "";
  font-family: "Font Awesome 5 Brands";
  font-size: 35px;
  display: inline-block;
}
.wrapper-quickview .quickview-sliderproduct .owl-nav .owl-prev:before {
  content: "\f053";
}
.wrapper-quickview .quickview-sliderproduct .owl-nav .owl-next:before {
  content: "\f054";
}
.wrapper-quickview .quickview-sliderproduct .owl-nav button.disabled {
  opacity: 0;
}
.wrapper-quickview .quickview-sliderproduct .owl-dots {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  text-align: center;
}
.wrapper-quickview .quickview-sliderproduct .owl-dots .owl-dot {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  border-width: 2px;
  background: #ddd;
  border: 1px solid #fff;
  outline: none;
  -webkit-transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.wrapper-quickview .quickview-sliderproduct .owl-dots .owl-dot.active {
  background: #3966b8;
}
.wrapper-quickview .quickview-sharingProduct {
  position: absolute;
  top: 10px;
  right: 8px;
  z-index: 6;
  visibility: visible;
  opacity: 1;
  cursor: pointer;
  -webkit-transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.wrapper-quickview .quickview-sharingProduct .sharing__iconCircleState {
  position: absolute;
  right: 0;
}
.wrapper-quickview.show-quickview {
  top: 0 !important;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.wrapper-quickview.show-quickview .jsQuickview {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.wrapper-quickview.show-quickview .modal-detailProduct .modal-dialog {
  pointer-events: auto;
}
.wrapper-quickview.show-quickview .modal-paramlink:before {
  -webkit-animation: widthAnimation 3.5s ease-in-out;
  animation: widthAnimation 3.5s ease-in-out;
}
@media (max-width: 767px) {
  .wrapper-quickview
    .modal-detailProduct
    .modal-dialog
    .modal-content
    #add-item-form2
    .actions-btn
    .btn-cart-product
    .status-add-to-cart {
    font-size: 12px;
    margin-right: 8px;
  }
  .wrapper-quickview
    .modal-detailProduct
    .modal-dialog
    .modal-content
    #add-item-form2
    .actions-btn
    .btn-cart-product
    .product-price {
    border: 0;
    margin: 0px;
  }
}

@-webkit-keyframes widthAnimation {
  0% {
    width: 0;
    opacity: 1;
  }
  80% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes widthAnimation {
  0% {
    width: 0;
    opacity: 1;
  }
  80% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

.wrapper-quickview + .quickviewOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms linear;
}

.wrapper-quickview .quickview-toolbarproduct {
  opacity: 0;
  visibility: hidden;
}

.wrapper-quickview.show-quickview + .quickviewOverlay,
.wrapper-quickview.show-quickview .quickview-toolbarproduct {
  opacity: 1;
  visibility: visible;
}

.wrapper-quickview.show-quickview .quickview-detailProduct--scroll {
  height: calc(100% - 56px);
}

.product-sharing {
  display: none;
  width: 35px;
  height: 35px;
  line-height: 35px;
}
@media (max-width: 991px) {
  .product-sharing {
    display: inline-block;
  }
}
.product-sharing .sharing__iconCircleState {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: block;
  border-radius: 50%;
  text-align: center;
  color: rgba(28, 27, 27, 0.5);
  background: #fff;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 5px rgba(54, 54, 54, 0.15);
  box-shadow: 0 1px 5px rgba(54, 54, 54, 0.15);
}
.product-sharing .sharing__iconCircleState .sharing__primaryState,
.product-sharing .sharing__iconCircleState .sharing__secondaryState {
  display: block;
  -webkit-transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1),
    -webkit-transform 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1),
    -webkit-transform 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: transform 0.4s cubic-bezier(0.75, 0, 0.125, 1),
    opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: transform 0.4s cubic-bezier(0.75, 0, 0.125, 1),
    opacity 0.4s cubic-bezier(0.75, 0, 0.125, 1),
    -webkit-transform 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}
.product-sharing .sharing__iconCircleState .sharing__primaryState svg,
.product-sharing .sharing__iconCircleState .sharing__secondaryState svg {
  height: 35px;
  width: 14px;
  display: block;
  line-height: 35px;
  margin: auto;
}
.product-sharing .sharing__iconCircleState .sharing__primaryState svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.product-sharing .sharing__iconCircleState .sharing__secondaryState {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}
.product-sharing a {
  right: 3px;
  width: 30px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  display: block;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 1px 5px rgba(54, 54, 54, 0.15);
  box-shadow: 0 1px 5px rgba(54, 54, 54, 0.15);
  -ms-transform: translateY(50%);
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.product-sharing a svg {
  height: 30px;
  line-height: 30px;
  width: 18px;
  display: block;
  margin: auto;
}
.product-sharing a:nth-child(2) {
  top: 50px;
  -ms-transition: all 150ms linear;
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
}
.product-sharing a:nth-child(3) {
  top: 90px;
  -ms-transition: all 150ms linear 100ms;
  -webkit-transition: all 150ms linear 100ms;
  transition: all 150ms linear 100ms;
}
.product-sharing a span.toollip-txt {
  border-radius: 2px;
  text-align: center;
  background: #1c1b1b;
  padding: 0 8px;
  visibility: hidden;
  opacity: 0;
  margin-right: 10px;
  position: absolute;
  top: 0px;
  right: 40px;
  z-index: 999;
  color: #ffffff;
  font-size: 10px;
  white-space: nowrap;
}
.product-sharing a span.toollip-txt:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  /*border-width: 7px 7px 7px 0;
          border-color: transparent #1c1b1b transparent transparent;*/
  border-width: 7px 0px 7px 7px;
  border-color: transparent transparent transparent #1c1b1b;
  position: absolute;
  right: -5px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.product-sharing a:nth-child(2) span.toollip-txt {
  -ms-transition: all 200ms linear 250ms;
  -webkit-transition: all 200ms linear 250ms;
  transition: all 200ms linear 250ms;
}
.product-sharing a:nth-child(3) span.toollip-txt {
  -ms-transition: all 200ms linear 350ms;
  -webkit-transition: all 200ms linear 350ms;
  transition: all 200ms linear 350ms;
}
.product-sharing a span.url-link {
  display: block;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.product-sharing.sharing-active .sharing__iconCircleState {
  background: #1c1b1b;
  color: #fff;
}
.product-sharing.sharing-active
  .sharing__iconCircleState
  .sharing__primaryState {
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.product-sharing.sharing-active
  .sharing__iconCircleState
  .sharing__secondaryState {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.product-sharing.sharing-active a {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.product-sharing.sharing-active a span.toollip-txt {
  visibility: visible;
  opacity: 1;
  margin-right: 0;
}
.product-sharing input.linkToCopy {
  opacity: 0;
  position: absolute;
  left: 180%;
  top: -20px;
}

.productDetail--gallery {
  display: none;
  width: 100%;
}
@media (max-width: 767px) {
  .productDetail--gallery {
    display: inline-block;
  }
}
.productDetail--gallery .product-container-gallery {
  position: relative;
  overflow: hidden;
}
.productDetail--gallery .product-container-gallery .product-gallery {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.productDetail--gallery .actionPrev-link {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 20;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background: #81868a;
  border: 1px solid #81868a;
}
.productDetail--gallery .actionPrev-link svg {
  fill: #fff;
  height: 40px;
  width: 20px;
  display: block;
  margin: auto;
}
.productDetail--gallery .product-percent {
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 9;
}
.productDetail--gallery .product-percent span {
  display: block;
  background: red;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 13px;
  border-radius: 0 0 10px 10px;
}
.productDetail--gallery .product-sharing {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.productDetail--gallery .productCarousel-slider {
  overflow: hidden;
}
.productDetail--gallery .productCarousel-slider .owl-nav button {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  margin: 0px;
  height: 35px;
  width: 35px;
  outline: none;
  text-align: center;
  background: #d9dde3;
  font-size: 0;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}
.productDetail--gallery .productCarousel-slider .owl-nav button.owl-prev {
  left: 0px;
  background-image: url(//theme.hstatic.net/200000299178/1000739054/14/left-arrow.png?v=76);
}
.productDetail--gallery .productCarousel-slider .owl-nav button.owl-next {
  right: 0px;
  background-image: url(//theme.hstatic.net/200000299178/1000739054/14/right-arrow.png?v=76);
}
.productDetail--gallery .productCarousel-slider .owl-nav button.disabled {
  opacity: 0;
}
.productDetail--gallery .productCarousel-slider .owl-dots {
  position: absolute;
  right: 10px;
  bottom: 10px;
  left: 10px;
  text-align: center;
}
.productDetail--gallery .productCarousel-slider .owl-dots .owl-dot {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  border-width: 2px;
  background: #ddd;
  border: 1px solid #fff;
  outline: none;
  -webkit-transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}
.productDetail--gallery .productCarousel-slider .owl-dots .owl-dot.active {
  background: #3966b8;
}
@media (min-width: 768px) {
  .productDetail--gallery .productCarousel-thumb {
    margin-top: 15px;
    position: initial;
  }
  .productDetail--gallery
    .productCarousel-thumb
    .product-thumb
    .product-thumb__item {
    display: block;
    border: 1px solid var(--shop-color-border);
  }
  .productDetail--gallery
    .productCarousel-thumb
    .current
    .product-thumb
    .product-thumb__item {
    border-color: #3966b8;
  }
}
@media (max-width: 767px) {
  .productDetail--gallery {
    padding: 0;
  }
}

/* Order Section */
#section-order-customer {
  background: #f5f5f5;
  padding: 30px 0 50px 0;
}
#section-order-customer .wrapper-heading-home {
  padding: 10px 0 30px 0;
  text-align: center;
}
@media (max-width: 767px) {
  #section-order-customer .wrapper-heading-home {
    padding: 0 15px 20px 15px;
  }
}
#section-order-customer .wrapper-heading-home h2 {
  font-size: 24px;
  line-height: 1.6;
  margin: 0;
  font-weight: bold;
}
#section-order-customer .wrapper-heading-home p {
  margin: 0;
}
#section-order-customer .wrapper-content-order .inner-content-order {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -8px;
}
#section-order-customer .wrapper-content-order .inner-content-order.no-center {
  justify-content: initial;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order {
  width: 25%;
  padding: 0 4px;
  margin-bottom: 8px;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order {
  border: 1px solid #f5f5f5;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order {
  background: white;
  padding: 10px 30px;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1199px) {
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order
    .inner-item-content-order {
    padding: 10px;
    font-size: 13px;
  }
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .overflow-a-order {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  cursor: pointer;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-1 {
  width: 20%;
}
@media (max-width: 1199px) {
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order
    .inner-item-content-order
    .inner-item-content-order-1 {
    width: 100%;
  }
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-1
  .item-content-image {
  order: 2;
}
@media (max-width: 1199px) {
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order
    .inner-item-content-order
    .inner-item-content-order-1
    .item-content-image {
    order: 1;
  }
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order
    .inner-item-content-order
    .inner-item-content-order-1
    .item-content-image
    img {
    max-width: 90%;
    border-radius: 5px;
  }
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-1
  .item-content-name {
  order: 1;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-2
  .item-content-price {
  margin: 3px 0 8px 0;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-2
  .item-content-action {
  position: relative;
  z-index: 1;
  text-align: right;
}
@media (max-width: 1199px) {
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order
    .inner-item-content-order
    .inner-item-content-order-2
    .item-content-action {
    text-align: left;
  }
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-2
  .item-content-action
  .action-buy {
  background: #096743;
  border: none;
  outline: none;
  box-shadow: none;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 20px;
  font-weight: bold;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
#section-order-customer
  .wrapper-content-order
  .inner-content-order
  .item-content-order
  .inner-item-content-order
  .inner-item-content-order-2
  .item-content-action
  .action-buy:hover {
  background: #dd0c1b;
}
@media (max-width: 1199px) {
  #section-order-customer {
    padding: 20px 0;
  }
  #section-order-customer .wrapper-content-order .inner-content-order {
    margin: 0;
  }
  #section-order-customer
    .wrapper-content-order
    .inner-content-order
    .item-content-order {
    width: 145px;
    padding: 0 0 0 0;
  }
}
@media (max-width: 767px) {
  #section-order-customer .wrapper-heading-home h2 {
    font-size: 20px;
  }
}

.modal-popupReorder {
  color: #333;
  background: rgba(3, 17, 27, 0.7);
}
.modal-popupReorder .modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 1.75rem auto;
  min-height: calc(100% - (1.75rem * 2));
}
@media (min-width: 768px) {
  .modal-popupReorder .modal-dialog-centered {
    width: 700px;
    max-width: none;
  }
}
@media (max-width: 991.9px) {
  .modal-popupReorder .modal-dialog-centered {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
    min-height: 100vh;
    margin: 0;
    width: 100%;
  }
  .modal-popupReorder .modal-dialog-centered .modal-content {
    min-height: 100vh;
  }
}
.modal-popupReorder .modal-dialog-centered .modal-content {
  width: 100%;
  margin-bottom: 50px;
}
.modal-popupReorder .modal-dialog-centered .modal-content .modal-header {
  position: relative;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-header
  .modal-title {
  font-size: 20px;
}
.modal-popupReorder .modal-dialog-centered .modal-content .close-popup-contact {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 2;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  outline: none;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .close-popup-contact:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .close-popup-contact
  svg {
  height: 14px;
  width: 14px;
  fill: #f3f3f3;
  vertical-align: 1px;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-body
  .detail-table-order {
  font-size: 13px;
  margin-top: 0px;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-body
  .detail-table-order
  table
  th {
  border-top: 0;
  padding-top: 0;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-body
  .detail-table-order
  table
  .info-item-order {
  display: flex;
  align-items: center;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-body
  .detail-table-order
  table
  .info-item-order
  img {
  width: 50px;
  margin: 0 10px 0 0;
}
.modal-popupReorder
  .modal-dialog-centered
  .modal-content
  .modal-body
  .action-re-buy {
  margin: 15px 0 0 0;
  text-align: right;
}
@media (max-width: 767px) {
  .modal-popupReorder
    .modal-dialog-centered
    .modal-content
    .modal-body
    .table-responsive
    #order_details {
    border: none;
    padding: 0;
  }
}
.modal-popupReorder .fade .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.modal-popupReorder .fade.show .modal-dialog-centered {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

#newsletter-footer {
  padding: 1.5rem 0;
  border-bottom: 1px solid #dee2e6;
}
#newsletter-footer .input-group {
  margin: 0;
}
#newsletter-footer .input-group label {
  margin: 0 30px 0 0;
  width: 170px;
}
@media (max-width: 991.9px) {
  #newsletter-footer .input-group label {
    margin: 0 0 5px 0;
    width: 100%;
  }
}
#newsletter-footer .input-group label i {
  margin: 0 5px 0 0;
}
#newsletter-footer .input-group .newsletter-outer-footer {
  width: calc(100% - 200px);
}
#newsletter-footer .input-group .newsletter-outer-footer .success-form-footer {
  margin: 10px 0 0 0;
  font-size: 13px;
}
#newsletter-footer .input-group .newsletter-outer-footer .error-form-footer {
  margin: 10px 0 0 0;
  font-size: 13px;
}
@media (max-width: 991.9px) {
  #newsletter-footer .input-group .newsletter-outer-footer {
    width: 100%;
  }
}
#newsletter-footer .input-group input[type="email"] {
  border: 1px solid #cacdd0;
  height: 40px;
  border-radius: 4px 0 0 4px;
  padding: 0 15px;
  width: calc(100% - 100px);
}
@media (max-width: 991.9px) {
  #newsletter-footer .input-group input[type="email"] {
    width: calc(100% - 100px);
  }
}
#newsletter-footer .input-group input[type="email"]:focus {
  border-color: #096743;
}
#newsletter-footer .input-group button {
  height: 40px;
  background: #096743;
  color: white;
  border: none;
  padding: 0 20px;
  border-radius: 0 4px 4px 0;
  outline: none;
  width: 100px;
}
#newsletter-footer .input-group button:hover {
  background: #dd0c1b;
}
@media (max-width: 1199.9px) {
  #newsletter-footer .footer-social {
    margin: 30px 0 0 0;
  }
}
#newsletter-footer .footer-social label {
  margin: 0 30px 0 0;
}
@media (max-width: 991.9px) {
  #newsletter-footer .footer-social label {
    margin: 0 0 5px 0;
    width: 100%;
  }
}
#newsletter-footer .footer-social ul {
  margin: 0;
  padding: 0;
}
#newsletter-footer .footer-social ul li {
  background: #096743;
  margin: 0 5px 0 0;
  padding: 0;
  width: 35px;
  height: 35px;
  line-height: 35px;
  justify-content: center;
  border-radius: 2px;
}
#newsletter-footer .footer-social ul li:hover {
  background: #dd0c1b;
}
#newsletter-footer .footer-social ul li a {
  color: white;
  display: inherit;
}
#newsletter-footer .footer-social ul li a i {
  font-size: 16px;
}
